import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Box,
  Container,
  Button,
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import JsPDF from "jspdf";
import html2canvas from "html2canvas";
import { doc, getDoc, addDoc, collection, setDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { db } from "../../firebase/firebase-config";
import { serverTimestamp } from "firebase/firestore";

function AddressForm() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();
  const navigate = useNavigate();
  const { state } = useLocation();
  const quote_data = state;
  // alert(JSON.stringify(state))
  // alert(JSON.stringify(state.slab.uploadedBy))
  const { stones, slab, stonePlan, stoneId, install } = quote_data;

  const onSubmit = async (data) => {
    if (Object.keys(errors).length === 0) {
      try {
        // Get the current counter value
        const counterDoc = await getDoc(doc(db, "Counters", "quoteCounter"));
        let currentCount = counterDoc.exists() ? counterDoc.data().count : 0;

        // Increment the counter value
        currentCount++;
        // alert("1. Existing Counter")

        // Update the counter document with the new count
        await setDoc(doc(db, "Counters", "quoteCounter"), {
          count: currentCount,
        });
        // alert("2. New Counter")

        // Format the quoteId as a zero-padded number (e.g., "00000001")
        const formattedQuoteId = currentCount.toString().padStart(8, "0");
        // alert("3. Formatted Counter")
        // alert(formattedQuoteId)

        await setDoc(doc(db, "Customers", data.email), {
          provider: state?.slab?.uploadedBy,
          ...data,
        });
        // alert("4. Customer Set")

        // alert("New Stone Plan Start")

        // Convert data URL to Blob
        const blob = await (async () => {
          const response = await fetch(stonePlan);
          return await response.blob();
        })();

        // Upload the Blob to Firebase Storage
        const storage = getStorage();
        const storageRef = ref(storage, `stone_plans/${formattedQuoteId}.jpg`);
        // alert(blob)
        console.log(blob);
        await uploadBytes(storageRef, blob);

        // Get the download URL of the uploaded file
        const downloadURL = await getDownloadURL(storageRef);
        // alert(downloadURL)
        // alert("New Stone Plan End")

        // Add the document with the formatted quoteId and createdAt timestamp
        const QuoteRef = await addDoc(collection(db, "Quotes"), {
          quoteId: formattedQuoteId, // Auto-incrementing field
          customerId: data?.email,
          stones: stones,
          // stonePlan: stonePlan,
          stonePlan: downloadURL, // Updated to use storage URL
          stoneId: stoneId,
          install: install,
          provider: state?.slab?.uploadedBy,
          createdAt: serverTimestamp(), // Current timestamp
        });
        // alert("6. Quote Id" + QuoteRef.id)
        navigate(`/quote/view/${QuoteRef.id}`);
        // alert("7. Starting Navigation to Next Page")
      } catch (error) {
        console.error(error);
        alert("Some Error Occured!");
        // alert("Errors: " + JSON.stringify(error))
        // alert(JSON.stringify(error))
      }
    } else {
      alert("Please fill out all required fields.");
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography mt={2} sx={{ fontWeight: "bold" }} variant="h5">
          Please fill in your address
        </Typography>
      </Box>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            p={2}
            m={2}
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              maxWidth: "400px",
              // marginLeft: "auto",
              backgroundColor: "#f7f7f7", // Light gray background color
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Box shadow for a subtle lift
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="firstName"
                  label="First Name"
                  variant="outlined"
                  margin="dense"
                  {...register("firstName", {
                    required: "First Name is required.",
                    maxLength: 80,
                  })}
                />
                {errors.firstName && (
                  <Typography style={{ color: "red" }}>
                    {errors.firstName.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="lastName"
                  label="Last Name"
                  variant="outlined"
                  margin="dense"
                  {...register("lastName", {
                    required: "Last Name is required",
                    maxLength: 100,
                  })}
                />
                {errors.lastName && (
                  <Typography style={{ color: "red" }}>
                    {errors.lastName.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="email"
                  label="Email"
                  variant="outlined"
                  margin="dense"
                  {...register("email", {
                    required: "Email is required.",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Invalid email format.",
                    },
                  })}
                />
                {errors.email && (
                  <Typography style={{ color: "red" }}>
                    {errors.email.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="mobileNumber"
                  label="Mobile Number"
                  variant="outlined"
                  margin="dense"
                  {...register("mobileNumber", {
                    required: "Mobile Number is required.",
                    minLength: {
                      value: 6,
                      message:
                        "Mobile Number must be at least 6 characters long.",
                    },
                    maxLength: {
                      value: 13,
                      message: "Mobile Number cannot exceed 12 characters.",
                    },
                  })}
                />
                {errors.mobileNumber && (
                  <Typography style={{ color: "red" }}>
                    {errors.mobileNumber.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  id="billingAddress"
                  label="Billing Address"
                  variant="outlined"
                  margin="dense"
                  {...register("billingAddress", {
                    required: "Billing Address is required",
                  })}
                />
                {errors.billingAddress && (
                  <Typography style={{ color: "red" }}>
                    {errors.billingAddress.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="suburb"
                  label="Suburb"
                  variant="outlined"
                  margin="dense"
                  {...register("suburb", { required: "Suburb is required." })}
                />
                {errors.suburb && (
                  <Typography style={{ color: "red" }}>
                    {errors.suburb.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="stateTerritory"
                  label="State/Territory"
                  variant="outlined"
                  margin="dense"
                  {...register("stateTerritory", {
                    required: "State/Territory is required.",
                  })}
                />
                {errors.stateTerritory && (
                  <Typography style={{ color: "red" }}>
                    {errors.stateTerritory.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="postcode"
                  label="Postcode"
                  variant="outlined"
                  margin="dense"
                  {...register("postcode", {
                    required: "Postcode is required.",
                  })}
                />
                {errors.postcode && (
                  <Typography style={{ color: "red" }}>
                    {errors.postcode.message}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  size="small"
                  id="country"
                  label="Country"
                  variant="outlined"
                  margin="dense"
                  {...register("country", { required: "Country is required." })}
                />
                {errors.country && (
                  <Typography style={{ color: "red" }}>
                    {errors.country.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              color="primary"
              sx={{ mt: 2, backgroundColor: "black" }} // Green button color
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
}

const QuoteUserForm = () => {
  return <AddressForm />;
};

export default QuoteUserForm;
