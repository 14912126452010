import React from 'react';
// import { auth } from '../../firebase/firebase-config';
// import { sendPasswordResetEmail } from 'firebase/auth';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";  
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const auth = getAuth();

  const onSubmit = async (data) => {
    try {
      await sendPasswordResetEmail(auth, data.email);
      // Email sent successfully
      console.log('Password reset email sent');
      // You can redirect the user or show a success message here
    } catch (error) {
      // Handle errors, e.g., email not found
      console.error('Error sending password reset email:', error.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '70vh',
        }}
      >
        <Typography component="h1" variant="h5">
          Stones Craft Design | Admin Panel rest
        </Typography>
        <Stack spacing={1} component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            {...register('email', { required: 'Email is required' })}
          />
          {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>}

          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Reset Password
          </Button>
        </Stack>
        <Typography variant="body2" color="textSecondary" align="center">
          Remember your password?{' '}
          <a href="/login">Sign in</a>
        </Typography>
      </Box>
    </Container>
  );
};

export default ResetPassword;
