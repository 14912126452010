import { Box, Button, Container, Grid, Typography, Tabs, Tab } from "@mui/material"
import React, {useState, useEffect, useContext} from "react"
import StoneCard from "../product_list/product"
import { useLocation, useNavigate, useParams, NavLink } from "react-router-dom";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import { doc, getDoc, collection, query, where, limit, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";
import { emphasize, styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import { Skeleton } from "@mui/material";
import NoProductsFound from "../product_list/noproducts";
import { ProviderContext } from "../layout/providerCtx";

const ProductPage = () => {
  const { brandId, setBrandId } = useContext(ProviderContext)
  const navigate = useNavigate();
  const {stone_Id} = useParams();
  const [relatedRange, setRelatedRange] = useState([])
  const [headImg, setHeadImg] = useState(null)
  const [stoneDetails, SetStoneDetails] = useState(null)
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    const fetchStoneDetails = async () => {
      const docRef = doc(db, "stones", `${stone_Id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        SetStoneDetails(docSnap.data())
        setBrandId(docSnap.data().uploadedBy)
        // alert(docSnap.data().uploadedBy)
        setLoading(false)
      } else {
        console.log("No such document!");
      }
    }
    fetchStoneDetails()
  }, [stone_Id])

  useEffect(()=>{
    const getRelatedBrandProducts = async () => {
      console.log("runnig related")
      if (stoneDetails!==null) {
        console.log("Queries Matched")
        const q = query(collection(db, "stones"), where("uploadedBy", "==", brandId), limit(6));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          console.log(doc.id, " => ", doc.data());
        });
    
        setRelatedRange(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      }
      return;
    }
    if (brandId!==null && brandId!=='undefined') {
      getRelatedBrandProducts()
    }

  }, [stone_Id, stoneDetails, loading])

  useEffect(()=>{
    const fetchURL = async () => {
      const storage = getStorage();
      // alert(stone_Id)
      const folderRef = ref(storage, `Stone Images/${stone_Id}`)

      try {
        const items = await listAll(folderRef);
    
        if (items.items.length > 0) {
          const firstItemRef = items.items[0];
          const downloadURL = await getDownloadURL(firstItemRef);
          setHeadImg(downloadURL)

          // console.log("Download URL of the first item:", downloadURL);
          return downloadURL;
        } else {
          console.log("Folder is empty.");
          return null;
        }
      } catch (error) {
        console.error("Error retrieving items in the folder:", error);
        return null;
      }
    }
    fetchURL()

  }, [stone_Id])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <Box py={1} sx={{ 
        backgroundColor: '#d3d3d3', color: "black",
        // display: 'flex', alignItems: 'center'
      }}>
        <Container >
          <Box px={4} sx={{
            display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: "space-between"
          }}>
            <Typography variant="h4" sx={{
              fontWeight: 'bold',
            }}>
              Product Details
            </Typography>
            <Typography p={2} my={2} sx={{
              backgroundColor: '#232323', width: 'fit-content', color: "whitesmoke", fontWeight: 'bold'
            }}>
              <Typography component={NavLink} sx={{textDecoration: "none", color: "whitesmoke", fontWeight: 'bold'}} to="/">
              Home
              </Typography> {' / Product Details'}
            </Typography>
          </Box>
        </Container>
      </Box>

      <Container>
        <Box px={4} py={4}>
          <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  {
                    headImg && headImg!==null 
                    ?
                    <img 
                      src={headImg}
                      width={"100%"}
                      height={'450px'}
                    />
                    :
                    <Skeleton sx={{ height: '450px' }} animation="wave" variant="rectangular" />
                  }
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box px={4} sx={{
                      height: "450px",
                      display: "flex", flexDirection: "column", justifyContent: "space-between"
                    }}>
                      <Box>
                        <Box my={2} sx={{
                          display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: "space-between"
                        }}>
                          <Box>
                            <Typography variant="h4">{loading?<Skeleton width={200} height={50}/>:stoneDetails?.name}</Typography>
                          </Box>

                          <Box>
                            <Typography variant="h4">{loading?<Skeleton width={50} height={50}/>:`$${stoneDetails?.price}`}</Typography>
                          </Box>
                        </Box>

                        <Box sx={{display: 'flex', flexDirection: 'row'}}>
                          {
                            loading
                            ?
                            <Skeleton width={100} height={50}/>
                            :
                            <Breadcrumbs aria-label="breadcrumb">
                              <Chip
                                label={stoneDetails?.brand} size="small" //clickable
                                //component="a" href="#stoneDetails?.brand" // variant="outlined"                          
                              />
                              <Chip
                                label={stoneDetails?.range} size="small" //clickable
                                //component="a" href="#basic-chip" // variant="outlined"                          
                              />
                              <Chip
                                label={stoneDetails?.name} size="small" //clickable
                                //component="a" href="#basic-chip" // variant="outlined"                          
                              />
                            </Breadcrumbs>

                          }

                        </Box>

                        <Box my={1} sx={{ display: 'flex', flexDirection: 'row' }}>
                          {/* {stoneDetails['Tones'].map((t) => {
                            let tv = t['tone_value'];
                            return (
                              <Box
                                m={0.5}
                                key={JSON.stringify(t)}
                                sx={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: '50%',
                                  border: '1px solid black',
                                  backgroundColor: tv,
                                  mr: 0.5,
                                }}
                              ></Box>
                            );
                          })} */}
                        </Box>

                        {
                          loading?
                          <Skeleton width={300} height={200}/>:
                          <Box>
                          <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label="Specifications" />
                            <Tab label="Description" />
                          </Tabs>

                          {tabValue === 0 && (
                            <Box py={2} sx={{ minHeight: "50%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                              <Typography variant="body1">
                                Slab Size: {stoneDetails?.size? stoneDetails.size:"Not Specified"}
                              </Typography>

                              <Typography variant="body1">
                                Slab Width: {stoneDetails?.width?stoneDetails.width:"Not Specified"}
                              </Typography>

                              <Typography variant="body1">
                                Colour Pattern: {stoneDetails?.color_pattern?stoneDetails.color_pattern:"Not Specified"}
                              </Typography>

                              <Typography variant="body1">
                                Slab Finish: {stoneDetails?.finish?stoneDetails.finish:"Not Specified"}
                              </Typography>

                              <Typography variant="body1">
                                Color: {stoneDetails?.color?stoneDetails.color:"Not Specified"}
                              </Typography>

                              <Typography variant="body1">
                                Dimensions: {stoneDetails?.dimensions?stoneDetails.dimensions:"Not Specified"}
                              </Typography>

                            </Box>
                          )}

                          {tabValue === 1 && (
                            <Box py={2} sx={{ minHeight: "40%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                              <Typography mt={4} variant="h6">
                                Details
                              </Typography>
                              <Typography variant="body1">
                                {stoneDetails.description}
                              </Typography>
                            </Box>
                          )}
                          </Box>
                        }

                      </Box>
                        {
                          loading?
                          <Skeleton width={100} height={50}/>:
                          <Box onClick={()=>navigate(`/quote/plan/${stone_Id}`)} sx={{
                            cursor: "pointer",
                            backgroundColor: '#262525', color: 'white', p: 2, mt: 2, fontWeight: 'bold', width: 'fit-content',
                            position: "relative", bottom: 0
                          }}>
                            Get Quote
                          </Box>    
                        }

                    </Box>
                </Grid>
          </Grid>

          <Box sx={{
              // backgroundColor: '#d3d3d3',
              // backgroundColor: '#232326',
          }}>
              <Box py={3} mt={10}>
                  <Typography variant="h3" sx={{textAlign: 'center', fontWeight: 'bold',}}>
                      Related Products
                  </Typography>
                  {/* {JSON.stringify(relatedRange)} */}
              </Box>

              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}>
                {
                  relatedRange.length>0 && relatedRange && relatedRange.map((stone) => {
                    return (
                        <StoneCard 
                        id={stone.id}
                        key={stone.id}
                        name={stone.name} 
                        image={stone.image} 
                        width={stone.width} 
                        size={stone.size} 
                        collection={stone.range} 
                        Tones = {stone.Tones}
                        price={stone.price}
                        stone={stone}
                        />
                    )
                  })                      
                }
              </Box>

              <Box>
                {relatedRange==null || relatedRange.length==0 && <NoProductsFound/>}
              </Box>


          </Box>

        </Box>
      </Container>
    </Box>
  )
}

export default ProductPage
