import React, { useContext, useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, Link as MaterialLink } from '@mui/material';
import { Link } from 'react-router-dom';
import {useNavigate} from "react-router-dom"
import { ProviderContext } from './providerCtx';

const Footer = () => {
    const navigate = useNavigate()
    const { brandName, brandImg, brandId } = useContext(ProviderContext)
    // alert(brandId)

    return (
        <Box px={{ xs: 3, sm: 10 }} py={{ xs: 5, sm: 10, md: 3 }} color='white' bgcolor='#262525'>
            <Container maxWidth='lg'>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Box borderBottom={1}>
                            Navigate
                        </Box>
                        <Box>
                            <Typography onClick={()=>navigate(`/p/${brandId}`)} sx={{cursor: "pointer"}}>
                                Home
                            </Typography>
                        </Box>
                        <Box>
                            <Typography onClick={()=>navigate(`/p/${brandId}`)} sx={{cursor: "pointer"}}>
                                Browse Stones
                            </Typography>
                        </Box>
                        <Box>
                            <Typography onClick={()=>navigate(`/contact`)} sx={{cursor: "pointer"}}>
                                Contact
                            </Typography>
                        </Box>
                        <Box>
                            <Typography onClick={()=>navigate("/login")} sx={{cursor: "pointer"}}>
                                Login
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <Box textAlign='center'>
                            <div style={{ width: { sm: '50vw', md: '25vw' }, margin: '75px 0', textAlign: 'center' }}>
                                <Typography onClick={()=>navigate(`p/${brandId}`)} variant='h3' sx={{cursor: "pointer"}}>
                                {brandName && brandName}
                                </Typography>
                                <Typography my={2} variant='caption'>All Rights Reserved &copy;Quote Provider Hub</Typography>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
export default Footer;