const apiKey =
  "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";

export function sendEmail(link, email) {
  const options = {
    method: "POST",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      "api-key": apiKey,
    },
    body: JSON.stringify({
      sender: {
        name: "Stone Quote",
        email: "no-reply@stonequote.app",
      },
      to: [{ email: email, name: "nick" }],
      htmlContent: `<!DOCTYPE html> <html> <body> <h3>You have received a new quote!</h3><a href=${link}>${link}</a></body>`,
      subject: "You have received a new quote! ",
    }),
  };

  fetch("https://api.brevo.com/v3/smtp/email", options)
    .then((response) => response.json())
    .then((response) => console.log(response))
    .catch((err) => console.error(err));
}
