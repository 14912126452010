import React from "react"
import {Box, Container, Grid, Typography} from "@mui/material"
import QuoteUserForm from "../components/quote/quote_form"
import { useLocation } from "react-router-dom"
import StonePlacer from "../components/drawing_page/stonePlacer"

const Quote = () => {
    const location = useLocation();
    const data = location.state;
    const stones = data.stones
      return (
        <Container sx={{
            overflow: 'auto',
            whiteSpace: 'nowrap'
        }}>
            {data && data !== null && <StonePlacer stones={stones} data={data}/>}
        </Container>
    )
}

export default Quote