import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { updateDoc, arrayUnion } from 'firebase/firestore';
import { addDoc, collection } from 'firebase/firestore';

import { auth, db } from '../../../firebase/firebase-config';
import { NavLink, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';

const ManageAdmins = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const navigate = useNavigate();
  const [admins, setAdmins] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  const onSubmit = async (data) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
      const user = userCredential.user;
    
      // Send a password reset email to the new admin
      await sendPasswordResetEmail(auth, data.email);
  
      setSuccessMessage(`Admin ${data.email} added successfully. Password reset email sent.`);
      setErrorMessage('');
      setValue('email', '');
      setValue('password', '');
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
      setErrorMessage(errorMessage);
      setSuccessMessage('');
    }
  };
  
  return (
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
        //   alignItems: 'center',
        //   justifyContent: 'center',
          height: '70vh',
        }}
      >
        <Typography component="h1" variant="h5">
          Create New Admins
        </Typography>
        <Typography component="h1" variant="body2">
          The newly created admin will recieve an email to reset their passwords, once you create a password for them.
        </Typography>
        <Typography component="h1" variant="body2">
          Further you will be logged out of your super-admin account and you will be logged-in automatically as newly created admin.
        </Typography>
        <Stack spacing={1} component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, width: '100%' }}>
          <TextField
            margin="normal"
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            {...register('email', { required: 'Email is required' })}
          />
          {errors.email && <p style={{ color: 'red' }}>{errors.email.message}</p>}
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            {...register('password', { required: 'Password is required' })}
          />
          {errors.password && <p style={{ color: 'red' }}>{errors.password.message} </p>}
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Add Admin
          </Button>
        </Stack>
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </Box>
    </Container>
  );
};

export default ManageAdmins;
