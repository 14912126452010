import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../firebase/firebase-config';
import { NavLink, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async (data) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password);
      const user = userCredential.user;
      navigate("/admin/dashboard");
      console.log(user);
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      setErrorMessage(errorMessage);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column', justifyContent: "center",
          alignItems: 'center', height: "70vh"
        }}
      >
        <Typography component="p" variant="h5">
        Stones Craft Design | Admin Panel
        </Typography>
        <Stack spacing={1} component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1, width: "100%" }}>
          <TextField
            margin="normal" // required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            {...register('email', { required: 'Email is required' })}
          />
          {errors.email && <p style={{color: "red"}}>{errors.email.message}</p>}
          
          <TextField
            margin="normal" // required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...register('password', { required: 'Password is required' })}
          />
          {errors.password && <p style={{color: "red"}}>{errors.password.message} </p>}

          {errorMessage && <p style={{color: "red"}}>{errorMessage}</p>}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Login
          </Button>
        </Stack>
        <Typography variant="body2" color="textSecondary" align="center">
          No account yet?{' '}
          <NavLink to="/reset-password">
            Reset Password
          </NavLink>
        </Typography>
      </Box>
    </Container>
  );
};

export default Login;
