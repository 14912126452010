import React from "react"
import StoneDrawer from "../components/drawing_page/stone_drawer"
import { Container } from "@mui/material"
import { useParams } from "react-router-dom"

const DrwingPage = () => {
    const stone_Id = useParams()
    return (
        <Container sx={{
            overflow: 'auto',
            whiteSpace: 'nowrap'
        }}>
            <StoneDrawer stone_Id={stone_Id}/>
        </Container>
    )
}


export default DrwingPage