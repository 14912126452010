import React, { useState, useEffect, useContext } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { getDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase/firebase-config";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Container,
  Grid,
  Box,
  Typography,
  Switch,
} from "@mui/material";

import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  addDoc,
  onSnapshot,
} from "firebase/firestore";
import { AuthContext } from "../layout/authCtx";
import SuperAdminManagement from "../components/supermanage";
import ManageAdmins from "../auth/manage-admins";

const storage = getStorage();

const AdminAccount = () => {
  const navigate = useNavigate();
  const {
    authenticated,
    setAuthenticated,
    userId,
    setUserId,
    loading,
    setLoading,
    superadmin,
  } = useContext(AuthContext);
  // const [authenticated, setAuthenticated] = useState(false)
  // const [userId, setUserId] = useState('')
  const [brandPrices, setBrandPrices] = useState({});

  useEffect(() => {
    if (userId && userId !== "") {
      const unsub = onSnapshot(doc(db, "Brands", userId), (doc) => {
        console.log("Current data: ", doc.data());
        setBrandPrices(doc.data());
      });

      return () => unsub();
    } else {
      console.log("Not loaded userId");
    }
  }, [userId]);
  // useEffect(()=>{
  //     onAuthStateChanged(auth, (user) => {
  //         if (user) {
  //           // User is signed in, see docs for a list of available properties
  //           // https://firebase.google.com/docs/reference/js/firebase.User
  //           const uid = user.uid;
  //           setAuthenticated(true)
  //           setUserId(user.uid)

  //           console.log("uid", uid)
  //         } else {
  //           console.log("user is logged out")
  //           setAuthenticated(false)
  //         }
  //       });
  // }, [])

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        navigate("/login");
        console.log("Signed out successfully");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const BrandCard = ({ providerId }) => {
    const [brandName, setBrandName] = useState("Stone Provider");
    const [brandImg, setBrandImg] = useState("");

    useEffect(() => {
      const fetchProvider = async () => {
        const docRef = doc(db, "Brands", providerId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Brand name and data:", docSnap.data());
          setBrandName(docSnap.data().name);
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document fr company name!");
        }

        try {
          const storage = getStorage();
          const storageRef = ref(storage, `Brands/${providerId}/logo.png`);
          const url = await getDownloadURL(storageRef);

          // alert(url)
          setBrandImg(url);
        } catch (error) {
          // Handle any errors
          console.error("Error downloading image:", error);
        }
      };
      if (providerId !== "" && providerId !== "undefined") {
        fetchProvider();
      }
    }, []);

    return (
      <Box>
        <Box
          px={4}
          py={6}
          sx={{
            backgroundColor: "#232326",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              backgroundColor: "#232326",
              fontWeight: "bold",
              marginBottom: 2,
              color: "#f0f0f0",
            }}
          >
            {brandName}
          </Typography>

          <img
            src={brandImg}
            alt={brandName}
            height={"120px"}
            width={"120px"}
          ></img>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <BrandCard providerId={userId} />
      <Container>
        <Grid container sx={{ my: 2 }}>
          <Grid item xs={11} md={11}>
            <Typography variant="h6">{brandPrices?.name}</Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            <Button variant="contained" color="error" onClick={handleLogout}>
              Logout
            </Button>
          </Grid>
        </Grid>
      </Container>

      <UpdatePrices userId={userId} brandPrices={brandPrices} />
    </Box>
  );
};

export default AdminAccount;

const UpdatePrices = ({ userId, brandPrices }) => {
  const [name, setName] = useState("");
  const [image, setImage] = useState(null);
  const [error, setError] = useState(false);
  const [phone, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (name == "" || image == null) {
      setError(true);
    } else {
      const docRef = await setDoc(
        doc(db, "Brands", userId),
        {
          name: name,
          phone: phone,
          email: email,
        },
        { merge: true }
      );
      console.log("Document Brand name written.");

      const logoRef = ref(storage, `Brands/${userId}/logo.png`);
      uploadBytes(logoRef, image).then((snapshot) => {
        console.log("Uploaded a blob or file!");
        setError(false);
        setName("");
        setImage(null);
        alert("Brand name and image added.");
      });
    }
  };

  // useEffect(() => {
  //   const slabArea = parseInt(slabWidth * slabDepth);
  //   const quantity_proportion = (pieceWidth * pieceDepth) / slabArea;
  //   const roundedQuantity =
  //     quantity_proportion % 1 > 0.45
  //       ? Math.ceil(quantity_proportion)
  //       : quantity_proportion;
  //   const roundedQuantityWithDecimal = Number(roundedQuantity.toFixed(2));

  //   setQuantity(roundedQuantityWithDecimal);
  // }, [pieceWidth, pieceDepth, slabWidth, slabDepth]);

  // useEffect(() => {
  //   const price =
  //     Number(Math.ceil(quantity)) * Number(slabPrice) +
  //     2 * (edgeType * pieceDepth) +
  //     2 * (edgeType * pieceWidth) +
  //     sinkCut +
  //     cookTopCut +
  //     Number(holesNo * holes) +
  //     Number(waterwall) +
  //     Number(splashback);

  //   console.log("price of this much");
  //   console.log(price);

  //   setPrice(price);
  // }, [
  //   edgeType,
  //   edgeProfile,
  //   sinkCut,
  //   cookTopCut,
  //   holes,
  //   holesNo,
  //   waterwall,
  //   waterwallBool,
  //   splashback,
  //   splashbackBool,
  //   pieceDepth,
  //   pieceWidth,
  // ]);

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={6}>
          <form>
            <Stack spacing={1}>
              <InputLabel>Brand/Admin Name</InputLabel>
              {/* <h2>You id is: {userId}</h2> */}
              <TextField
                required
                sx={{ my: 1, width: "400px" }}
                label="Name"
                value={name}
                size="small"
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                required
                sx={{ my: 1, width: "400px" }}
                label="Phone"
                value={phone}
                size="small"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <TextField
                required
                sx={{ my: 1, width: "400px" }}
                label="Email"
                value={email}
                size="small"
                onChange={(e) => setEmail(e.target.value)}
              />

              <InputLabel>Brand/Admin Image</InputLabel>
              <input type="file" required onChange={handleImageChange} />
              {error && (
                <p style={{ color: "red" }}>Please add in both fields above</p>
              )}
              <Button
                type="submit"
                variant="contained"
                onClick={handleUpload}
                sx={{ my: 1, width: "400px" }}
              >
                Upload
              </Button>
            </Stack>
          </form>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>
      <Box>
        <EdgeTypeVariablesForm userId={userId} brandPrices={brandPrices} />
        <EdgeTypeImageForm
          userId={userId}
          category="Edge Type"
          input_list={edge_types}
        />

        <EdgeProfileVariablesForm userId={userId} brandPrices={brandPrices} />
        <EdgeTypeImageForm
          userId={userId}
          category="Edge Profile"
          input_list={edge_profiles}
        />

        <SinkCutoutVariablesForm userId={userId} brandPrices={brandPrices} />
        <EdgeTypeImageForm
          userId={userId}
          category="Sink Cutout"
          input_list={sink_cutouts}
        />

        <CooktopCutoutVariablesForm userId={userId} brandPrices={brandPrices} />
        <EdgeTypeImageForm
          userId={userId}
          category="Cooktop Cutout"
          input_list={cooktop_cutouts}
        />

        <OtherVariablesForm userId={userId} brandPrices={brandPrices} />
        <EdgeTypeImageForm
          userId={userId}
          category="Sketch"
          input_list={sketch}
        />
      </Box>
    </Container>
  );
};

const edge_types = [
  {
    value: "Single Edge",
    label: "Single Edge",
    price: 1,
  },
  {
    value: "Double Edge",
    label: "Double Edge",
    price: 1.5,
  },
  {
    value: "Mitred Apron",
    label: "Mitred Apron",
    price: 2,
  },
];

const edge_profiles = [
  {
    value: "Pencil",
    label: "Pencil",
    price: 20,
  },
  {
    value: "Bull Nose",
    label: "Bull Nose",
    price: 100,
  },
  {
    value: "Shark Nose",
    label: "Shark Nose",
    price: 100,
  },
  {
    value: "Splayed",
    label: "Splayed",
    price: 100,
  },
];

const sink_cutouts = [
  { value: "None", label: "None", price: 0 },
  { value: "Drop In Sink", label: "Drop In Sink", price: 20 },
  {
    value: "Undermount Single Bowl",
    label: "Undermount Single Bowl",
    price: 100,
  },
  {
    value: "Undermount Double Bowl",
    label: "Undermount Double Bowl",
    price: 200,
  },
  // { value: 'undermount_twin_polished', label: 'Undermount Twin Sinks (Polished Cut Out)', price: 20 },
  // { value: 'flushmount_polished', label: 'Flushmount Sink (Polished Cut Out)', price: 20 }
];

const cooktop_cutouts = [
  { value: "None Cooktop", label: "None", price: 0 },
  { value: "Drop In Cooktop", label: "Drop In Cooktop", price: 20 },
  { value: "Flushmount Cooktop", label: "Flushmount Cooktop", price: 200 },
];

const other_variables = [
  { value: "waterfall", label: "Waterfall", price: 200 },
  { value: "splashback", label: "Splash Back", price: 50 },
  { value: "holes", label: "Holes", price: 5 },
  // { value: "installation", label: "Installation", price: 2000 },
];

const sketch = [
  { value: "Sink on Sketch", label: "Sink on Sketch", price: 0 },
  { value: "Cooktop on Sketch", label: "Cooktop on Sketch", price: 0 },
];

const EdgeTypeVariablesForm = ({ userId, brandPrices }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setValue,
    reset,
  } = useForm({
    // defaultValues: edge_types,
  });
  const [offerings, setOfferings] = useState({
    "Single Edge": false,
    "Double Edge": false,
    "Mitred Edge": false,
  });
  useEffect(() => {
    const newBrandPrices = {
      "Single Edge": false,
      "Double Edge": false,
      "Mitred Edge": false,
    };
    if (brandPrices && brandPrices["Offering"] !== undefined) {
      for (var key in brandPrices["Offering"]) {
        newBrandPrices[key] = brandPrices["Offering"][key];
      }
    }
    setOfferings(newBrandPrices);
  }, [brandPrices]);

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    const DbRef = doc(db, "Brands", userId);
    setDoc(DbRef, { "Edge Type": data }, { merge: true });
  };
  const onChangeOffering = (checked, label) => {
    const DbRef = doc(db, "Brands", userId);
    setDoc(DbRef, { Offering: { [label]: checked } }, { merge: true });
    setOfferings((prev) => ({ ...prev, [label]: checked }));
  };
  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Edge Type */}
          <InputLabel>Edge Type</InputLabel>
          {edge_types.map((key) => {
            return (
              <div key={key["label"]}>
                <Controller
                  name={key["label"]}
                  control={control}
                  defaultValue=""
                  rules={{ required: `${key["label"]} is required.` }}
                  render={({ field }) => (
                    <TextField
                      type="number" //fullWidth
                      sx={{ my: 1, width: "400px" }}
                      {...field}
                      label={key["label"]}
                      variant="outlined"
                      size="small"
                      error={!!errors[key["label"]]}
                      helperText={errors[key["label"]]?.message}
                    />
                  )}
                />
                <Switch
                  onChange={(e) =>
                    onChangeOffering(e.target.checked, key["label"])
                  }
                  checked={offerings[key["label"]] || false}
                />{" "}
                Offering?
              </div>
            );
          })}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Add Edge Type Prices
          </Button>
        </form>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">Edge Type Prices</Typography>
        {brandPrices &&
          brandPrices["Edge Type"] &&
          Object.keys(brandPrices["Edge Type"]).map((i, index) => (
            <Typography key={index} variant="body1">
              {index + 1}
              {". "}
              {i}
              {": "}
              {brandPrices["Edge Type"][i]}
            </Typography>
          ))}
      </Grid>
    </Grid>
  );
};

const EdgeTypeImageForm = ({ userId, category, input_list }) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    reset,
  } = useForm();

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);

  const onSubmit = async (data) => {
    console.log("Image Form Data:", data);

    // Upload images to Firebase Storage
    const storageRef = ref(storage, `Frontend/Images/StoneProps/${userId}/`);

    // Iterate through form data and upload images
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key]?.length) {
        const file = data[key][0]; // Assuming only one file is selected
        const imageRef = ref(storageRef, `${category}-${key}`);
        await uploadBytes(imageRef, file);
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {input_list.map((key) => (
            <div key={key["label"]}>
              <Controller
                name={key["label"]}
                control={control}
                defaultValue=""
                rules={{ required: `${key["label"]} image is required.` }}
                render={({ field }) => (
                  <>
                    {/* Image Input */}
                    <InputLabel>{key["label"]} Image</InputLabel>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => setValue(key["label"], e.target.files)}
                    />
                    {errors[key["label"]] && (
                      <p style={{ color: "red" }}>
                        {errors[key["label"]].message}
                      </p>
                    )}
                  </>
                )}
              />
            </div>
          ))}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Upload {category} Images
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};
const EdgeProfileVariablesForm = ({ userId, brandPrices }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setValue,
    reset,
  } = useForm({
    // defaultValues: EdgeProfileVariables,
  });
  const [offerings, setOfferings] = useState({
    Pencil: false,
    "Bull Nose": false,
    "Shark Nose": false,
    Splayed: false,
  });
  useEffect(() => {
    const newBrandPrices = {
      Pencil: false,
      "Bull Nose": false,
      "Shark Nose": false,
      Splayed: false,
    };
    if (brandPrices && brandPrices["Offering"] !== undefined) {
      for (var key in brandPrices["Offering"]) {
        newBrandPrices[key] = brandPrices["Offering"][key];
      }
      setOfferings(newBrandPrices);
    }
  }, [brandPrices]);

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    const DbRef = doc(db, "Brands", userId);
    setDoc(DbRef, { "Edge Profile": data }, { merge: true });
  };
  const onChangeOffering = (checked, label) => {
    const DbRef = doc(db, "Brands", userId);
    setDoc(DbRef, { Offering: { [label]: checked } }, { merge: true });
    setOfferings((prev) => ({ ...prev, [label]: checked }));
  };

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);

  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {/* Edge Profile */}
            <Grid item xs={12}>
              <InputLabel>Edge Profile</InputLabel>
              {edge_profiles.map((key) => {
                return (
                  <div key={key.label}>
                    <Controller
                      name={key.label}
                      control={control}
                      defaultValue=""
                      rules={{ required: `${key.label} is required.` }}
                      render={({ field }) => (
                        <TextField
                          type="number" //fullWidth
                          sx={{ my: 1, width: "400px" }}
                          {...field}
                          label={key.label}
                          variant="outlined"
                          size="small"
                          error={!!errors[key.label]}
                          helperText={errors[key.label]?.message}
                        />
                      )}
                    />
                    <Switch
                      onChange={(e) =>
                        onChangeOffering(e.target.checked, key["label"])
                      }
                      checked={offerings[key["label"]] || false}
                    />{" "}
                    Offering?
                  </div>
                );
              })}
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Add Edge Profile Prices
          </Button>
        </form>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">Edge Profile Prices</Typography>
        {brandPrices &&
          brandPrices["Edge Profile"] &&
          Object.keys(brandPrices["Edge Profile"]).map((i, index) => (
            <Typography key={index} variant="body1">
              {index + 1}
              {". "}
              {i}
              {": "}
              {brandPrices["Edge Profile"][i]}
            </Typography>
          ))}
      </Grid>
    </Grid>
  );
};

const SinkCutoutVariablesForm = ({ userId, brandPrices }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setValue,
    reset,
  } = useForm({
    // defaultValues: EdgeProfileVariables,
  });
  const [offerings, setOfferings] = useState({
    None: false,
    "Drop In Sink": false,
    "Undermount Single Bowl": false,
    "Undermount Double Bowl": false,
  });
  useEffect(() => {
    const newBrandPrices = {
      None: false,
      "Drop In Sink": false,
      "Undermount Single Bowl": false,
      "Undermount Double Bowl": false,
    };
    if (brandPrices && brandPrices["Offering"] !== undefined) {
      for (var key in brandPrices["Offering"]) {
        newBrandPrices[key] = brandPrices["Offering"][key];
      }
      setOfferings(newBrandPrices);
    }
  }, [brandPrices]);

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    const DbRef = doc(db, "Brands", userId);
    setDoc(DbRef, { "Sink Cutout": data }, { merge: true });
  };
  const onChangeOffering = (checked, label) => {
    const DbRef = doc(db, "Brands", userId);
    setDoc(DbRef, { Offering: { [label]: checked } }, { merge: true });
    setOfferings((prev) => ({ ...prev, [label]: checked }));
  };

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);

  return (
    <Grid container>
      <Grid xs={12} md={6}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {/* SinkCutoutVariables */}
            <Grid item xs={12}>
              <InputLabel>Sink Cutout</InputLabel>
              {sink_cutouts.map((key) => {
                return (
                  <div key={key.label}>
                    <Controller
                      name={key.label}
                      control={control}
                      defaultValue=""
                      rules={{ required: `${key.label} is required.` }}
                      render={({ field }) => (
                        <TextField
                          type="number" //fullWidth
                          sx={{ my: 1, width: "400px" }}
                          {...field}
                          label={key.label}
                          variant="outlined"
                          size="small"
                          error={!!errors[key.label]}
                          helperText={errors[key.label]?.message}
                        />
                      )}
                    />
                    <Switch
                      onChange={(e) =>
                        onChangeOffering(e.target.checked, key["label"])
                      }
                      checked={offerings[key["label"]] || false}
                    />{" "}
                    Offering?
                  </div>
                );
              })}
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Add Sink Cutout Prices
          </Button>
        </form>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">Sink Cutout Prices</Typography>
        {brandPrices &&
          brandPrices["Sink Cutout"] &&
          Object.keys(brandPrices["Sink Cutout"]).map((i, index) => (
            <Typography key={index} variant="body1">
              {index + 1}
              {". "}
              {i}
              {": "}
              {brandPrices["Sink Cutout"][i]}
            </Typography>
          ))}
      </Grid>
    </Grid>
  );
};

const CooktopCutoutVariablesForm = ({ userId, brandPrices }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setValue,
    reset,
  } = useForm({
    // defaultValues: EdgeProfileVariables,
  });
  const [offerings, setOfferings] = useState({
    "None Cooktop": false,
    "Drop In Cooktop": false,
    "Flushmount Cooktop": false,
  });
  useEffect(() => {
    const newBrandPrices = {
      "None Cooktop": false,
      "Drop In Cooktop": false,
      "Flushmount Cooktop": false,
    };

    if (brandPrices && brandPrices["Offering"] !== undefined) {
      for (var key in brandPrices["Offering"]) {
        newBrandPrices[key] = brandPrices["Offering"][key];
      }
      setOfferings(newBrandPrices);
    }
  }, [brandPrices]);
  const onSubmit = (data) => {
    console.log("Form Data:", data);
    const DbRef = doc(db, "Brands", userId);
    setDoc(DbRef, { "Cooktop Cutout": data }, { merge: true });
  };
  const onChangeOffering = (checked, label) => {
    const DbRef = doc(db, "Brands", userId);
    setDoc(DbRef, { Offering: { [label]: checked } }, { merge: true });
    setOfferings((prev) => ({ ...prev, [label]: checked }));
  };

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);

  return (
    <Grid container>
      <Grid xs={12} md={6}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel>Cooktop Cutout</InputLabel>
              {cooktop_cutouts.map((key) => {
                return (
                  <div key={key.label}>
                    <Controller
                      name={key.label}
                      control={control}
                      defaultValue=""
                      rules={{ required: `${key.label} is required.` }}
                      render={({ field }) => (
                        <TextField
                          type="number" //fullWidth
                          sx={{ my: 1, width: "400px" }}
                          {...field}
                          label={key.label}
                          variant="outlined"
                          size="small"
                          error={!!errors[key.label]}
                          helperText={errors[key.label]?.message}
                        />
                      )}
                    />
                    <Switch
                      onChange={(e) =>
                        onChangeOffering(e.target.checked, key["value"])
                      }
                      checked={offerings[key["value"]] || false}
                    />{" "}
                    Offering?
                  </div>
                );
              })}
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Add Cooktop Cutout Prices
          </Button>
        </form>
      </Grid>
      <Grid
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">Cooktop Cutout Prices</Typography>
        {brandPrices &&
          brandPrices["Cooktop Cutout"] &&
          Object.keys(brandPrices["Cooktop Cutout"]).map((i, index) => (
            <Typography key={index} variant="body1">
              {index + 1}
              {". "}
              {i}
              {": "}
              {brandPrices["Cooktop Cutout"][i]}
            </Typography>
          ))}
      </Grid>
    </Grid>
  );
};

const OtherVariablesForm = ({ userId, brandPrices }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    setValue,
    reset,
  } = useForm({
    // defaultValues: EdgeProfileVariables,
  });

  const onSubmit = (data) => {
    console.log("Form Data:", data);
    const DbRef = doc(db, "Brands", userId);
    setDoc(DbRef, { Others: data }, { merge: true });
  };

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);

  return (
    <Grid container>
      <Grid xs={12} md={6}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            {/* CooktopCutoutVariables */}
            <Grid item xs={12}>
              <InputLabel>Other Prices</InputLabel>
              {other_variables.map((key) => {
                return (
                  <div key={key.label}>
                    <Controller
                      // Exception
                      name={key.value}
                      control={control}
                      defaultValue=""
                      rules={{ required: `${key.label} is required.` }}
                      render={({ field }) => (
                        <TextField
                          type="number" //fullWidth
                          sx={{ my: 1, width: "400px" }}
                          {...field}
                          label={key.label}
                          variant="outlined"
                          size="small"
                          error={!!errors[key.label]}
                          helperText={errors[key.label]?.message}
                        />
                      )}
                    />
                  </div>
                );
              })}
            </Grid>
            <Grid item xs={4.3}>
              <Controller
                // Exception
                name={"slabs_required"}
                control={control}
                defaultValue=""
                rules={{ required: `${"Slabs quantity"} is required.` }}
                render={({ field }) => (
                  <TextField
                    type="number" //fullWidth
                    // sx={{ my: 1, width: "250px" }}
                    {...field}
                    label={"Slabs required"}
                    variant="outlined"
                    size="small"
                    error={!!errors["slabs_required"]}
                    helperText={errors["slabs_required"]?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4.2}>
              <Controller
                // Exception
                name={"installation"}
                control={control}
                defaultValue=""
                rules={{ required: `${"Installation"} is required.` }}
                render={({ field }) => (
                  <TextField
                    type="number" //fullWidth
                    // sx={{ my: 1, width: "250px" }}
                    {...field}
                    label={"Installation"}
                    variant="outlined"
                    size="small"
                    error={!!errors["installation"]}
                    helperText={errors["installation"]?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              Else <br />
              <Controller
                // Exception
                name={"above_than_slab_installation"}
                control={control}
                defaultValue=""
                rules={{ required: `${"Installation"} is required.` }}
                render={({ field }) => (
                  <TextField
                    type="number" //fullWidth
                    sx={{ my: 1, width: "400px" }}
                    {...field}
                    label={"Installation"}
                    variant="outlined"
                    size="small"
                    error={!!errors["above_than_slab_installation"]}
                    helperText={errors["above_than_slab_installation"]?.message}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Add Other Prices
          </Button>
        </form>
      </Grid>
      <Grid
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">Other Prices</Typography>
        {brandPrices &&
          brandPrices["Others"] &&
          Object.keys(brandPrices["Others"]).map((i, index) => (
            <Typography key={index} variant="body1">
              {index + 1}
              {". "}
              {i}
              {": "}
              {brandPrices["Others"][i]}
            </Typography>
          ))}
      </Grid>
    </Grid>
  );
};
