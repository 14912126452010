import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  deleteDoc,
  doc,
  query,
  startAfter,
  where,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase/firebase-config";

export const Filters = () => {
  const [data, setData] = useState([]);
  const [slabfinishdata, setslabfinishData] = useState([]);
  const [slabsizedata, setslabsizeData] = useState([]);
  const [colourtonedata, setcolourtoneData] = useState([]);
  const [colourpatterndata, setcolourpatternData] = useState([]);

  const qs = query(collection(db, "filters"));
  const filtersRef = collection(db, "filters");

  const qsslabFinishRef = query(collection(db, "filters-slabfinish"));
  const qsslabSizeRef = query(collection(db, "filters-slabsize"));
  const qscolourToneRef = query(collection(db, "filters-colourtone"));
  const qscolourPatternRef = query(collection(db, "filters-colourpattern"));

  const slabFinishRef = collection(db, "filters-slabfinish");
  const slabSizeRef = collection(db, "filters-slabsize");
  const colourToneRef = collection(db, "filters-colourtone");
  const colourPatternRef = collection(db, "filters-colourpattern");

  const [filter, setFilter] = useState("");
  const [slabFinish, setSlabFinish] = useState("");
  const [slabSize, setSlabSize] = useState("");
  const [colourTone, setColourTone] = useState("");
  const [colourPattern, setPattern] = useState("");

  const [edit, setEdit] = useState(false);
  const [editslabFinish, seteditSlabFinish] = useState(false);
  const [editslabSize, seteditSlabSize] = useState(false);
  const [editcolourTone, seteditColourTone] = useState(false);
  const [editcolourPattern, seteditPattern] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [editslabFinishFilter, seteditslabFinishFilter] = useState(null);
  const [editslabSizeFilter, seteditslabSizeFilter] = useState(null);
  const [editcolourToneFilter, seteditcolourToneFilter] = useState(null);
  const [editcolourPatternFilter, seteditcolourPatternFilter] = useState(null);

  useEffect(() => {
    fetchFilters();
    fetchSlabsFinish();
    fetchSlabSize();
    fetchColourTone();
    fetchColourPattern();
  }, []);

  const fetchFilters = async () => {
    try {
      const querySnapshot = await getDocs(qs);
      setData(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };
  const fetchSlabsFinish = async () => {
    try {
      const querySnapshot = await getDocs(qsslabFinishRef);
      setslabfinishData(
        querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };
  const fetchSlabSize = async () => {
    try {
      const querySnapshot = await getDocs(qsslabSizeRef);
      setslabsizeData(
        querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };
  const fetchColourTone = async () => {
    try {
      const querySnapshot = await getDocs(qscolourToneRef);
      setcolourtoneData(
        querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };
  const fetchColourPattern = async () => {
    try {
      const querySnapshot = await getDocs(qscolourPatternRef);
      setcolourpatternData(
        querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };
  const addFilter = async () => {
    await addDoc(filtersRef, {
      name: filter,
    });
    setFilter("");
    setData((prev) => [...prev, { name: filter }]);
  };
  const addSlabSize = async () => {
    await addDoc(slabSizeRef, {
      name: slabSize,
    });
    setSlabSize("");
    setslabsizeData((prev) => [...prev, { name: slabSize }]);
  };
  const addslabFinish = async () => {
    await addDoc(slabFinishRef, {
      name: slabFinish,
    });
    setSlabFinish("");
    setslabfinishData((prev) => [...prev, { name: slabFinish }]);
  };
  const addColorTone = async () => {
    await addDoc(colourToneRef, {
      name: colourTone,
    });
    setColourTone("");
    setcolourtoneData((prev) => [...prev, { name: colourTone }]);
  };
  const addColourPattern = async () => {
    await addDoc(colourPatternRef, {
      name: colourPattern,
    });
    setPattern("");
    setcolourpatternData((prev) => [...prev, { name: colourPattern }]);
  };

  const deleteFilter = async (id) => {
    await deleteDoc(doc(db, "filters", id));
    setData((prev) => prev.filter((v) => v.id != id));
  };
  const deleteSlabSize = async (id) => {
    await deleteDoc(doc(db, "filters-slabsize", id));
    setslabsizeData((prev) => prev.filter((v) => v.id != id));
  };
  const deleteSlabFinish = async (id) => {
    await deleteDoc(doc(db, "filters-slabfinish", id));
    setslabfinishData((prev) => prev.filter((v) => v.id != id));
  };
  const deleteColourTone = async (id) => {
    await deleteDoc(doc(db, "filters-colourtone", id));
    setcolourtoneData((prev) => prev.filter((v) => v.id != id));
  };
  const deleteColourPattern = async (id) => {
    await deleteDoc(doc(db, "filters-colourpattern", id));
    setcolourpatternData((prev) => prev.filter((v) => v.id != id));
  };
  const editSlabSize = async () => {
    const docRef = doc(db, "filters-slabsize", editslabSizeFilter);
    await updateDoc(docRef, { name: slabSize });
    setslabsizeData((prev) =>
      prev.map((v) => {
        if (v.id == editslabSizeFilter) {
          return { name: slabSize, id: v.id };
        }
        return v;
      })
    );
    seteditSlabSize(false);
    setSlabSize("");
    seteditslabSizeFilter(null);
  };
  const editFilter = async () => {
    const docRef = doc(db, "filters", selectedFilter);
    await updateDoc(docRef, { name: filter });
    setData((prev) =>
      prev.map((v) => {
        if (v.id == selectedFilter) {
          return { name: filter, id: v.id };
        }
        return v;
      })
    );
    setEdit(false);
    setFilter("");
    setSelectedFilter(null);
  };
  const editSlabFinish = async () => {
    const docRef = doc(db, "filters-slabfinish", editslabFinishFilter);
    await updateDoc(docRef, { name: slabFinish });
    setslabfinishData((prev) =>
      prev.map((v) => {
        if (v.id == editslabFinishFilter) {
          return { name: slabFinish, id: v.id };
        }
        return v;
      })
    );
    seteditSlabFinish(false);
    setSlabFinish("");
    seteditslabSizeFilter(null);
  };
  const editColourTone = async () => {
    const docRef = doc(db, "filters-colourtone", editcolourToneFilter);
    await updateDoc(docRef, { name: colourTone });
    setcolourtoneData((prev) =>
      prev.map((v) => {
        if (v.id == editcolourPatternFilter) {
          return { name: colourTone, id: v.id };
        }
        return v;
      })
    );
    seteditColourTone(false);
    setColourTone("");
    seteditcolourToneFilter(null);
  };
  const editColourPattern = async () => {
    const docRef = doc(db, "filters-colourpattern", editcolourPatternFilter);
    await updateDoc(docRef, { name: colourPattern });
    setcolourpatternData((prev) =>
      prev.map((v) => {
        if (v.id == editcolourPatternFilter) {
          return { name: colourPattern, id: v.id };
        }
        return v;
      })
    );
    seteditPattern(false);
    setPattern("");
    seteditcolourPatternFilter(null);
  };
  return (
    <Box p={3}>
      <h2>Filters</h2>
      <Box display={"flex"} justifyContent={"space-between"}>
        <h3>Stond Brand</h3>

        <Box>
          <TextField
            label={edit ? "Edit Stond Brand" : "Add Stond Brand"}
            variant="outlined"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <Button
            onClick={() => {
              edit ? editFilter() : addFilter();
            }}
          >
            {edit ? "Edit" : "Add"}
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} sx={{ minWidth: 200 }}>
                Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 &&
              data.map((v) => (
                <TableRow key={v.id}>
                  <TableCell>{v.name}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        setEdit(true);
                        setSelectedFilter(v.id);
                        setFilter(v.name);
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => deleteFilter(v.id)}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display={"flex"} mt={2} justifyContent={"space-between"}>
        <h3>Slab Finish</h3>

        <Box>
          <TextField
            label={editslabFinish ? "Edit Slab Finish" : "Add Slab Finish"}
            variant="outlined"
            value={slabFinish}
            onChange={(e) => setSlabFinish(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <Button
            onClick={() => {
              editslabFinish ? editSlabFinish() : addslabFinish();
            }}
          >
            {editslabFinish ? "Edit" : "Add"}
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} sx={{ minWidth: 200 }}>
                Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slabfinishdata.length > 0 &&
              slabfinishdata.map((v) => (
                <TableRow key={v.id}>
                  <TableCell>{v.name}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        seteditSlabFinish(true);
                        seteditslabFinishFilter(v.id);
                        setSlabFinish(v.name);
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => deleteSlabFinish(v.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display={"flex"} mt={2} justifyContent={"space-between"}>
        <h3>Slab size</h3>

        <Box>
          <TextField
            label={editslabSize ? "Edit Slab size" : "Add Slab size"}
            variant="outlined"
            value={slabSize}
            onChange={(e) => setSlabSize(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <Button
            onClick={() => {
              editslabSize ? editSlabSize() : addSlabSize();
            }}
          >
            {editslabSize ? "Edit" : "Add"}
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} sx={{ minWidth: 200 }}>
                Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {slabsizedata.length > 0 &&
              slabsizedata.map((v) => (
                <TableRow key={v.id}>
                  <TableCell>{v.name}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        seteditSlabSize(true);
                        seteditslabSizeFilter(v.id);
                        setSlabSize(v.name);
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => deleteSlabSize(v.id)}>Delete</Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display={"flex"} mt={2} justifyContent={"space-between"}>
        <h3>Colour Tone</h3>

        <Box>
          <TextField
            label={editcolourTone ? "Edit Colour Tone" : "Add Colour Tone"}
            variant="outlined"
            value={colourTone}
            onChange={(e) => setColourTone(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <Button
            onClick={() => {
              editcolourTone ? editColourTone() : addColorTone();
            }}
          >
            {editcolourTone ? "Edit" : "Add"}
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} sx={{ minWidth: 200 }}>
                Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {colourtonedata.length > 0 &&
              colourtonedata.map((v) => (
                <TableRow key={v.id}>
                  <TableCell>{v.name}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        seteditColourTone(true);
                        seteditcolourToneFilter(v.id);
                        setColourTone(v.name);
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => deleteColourTone(v.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display={"flex"} mt={2} justifyContent={"space-between"}>
        <h3>Colour Pattern</h3>

        <Box>
          <TextField
            label={
              editcolourPattern ? "Edit Colour Pattern" : "Add Colour Pattern"
            }
            variant="outlined"
            value={colourPattern}
            onChange={(e) => setPattern(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <Button
            onClick={() => {
              editcolourPattern ? editColourPattern() : addColourPattern();
            }}
          >
            {editcolourPattern ? "Edit" : "Add"}
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={3} sx={{ minWidth: 200 }}>
                Name
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {colourpatterndata.length > 0 &&
              colourpatterndata.map((v) => (
                <TableRow key={v.id}>
                  <TableCell>{v.name}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        seteditPattern(true);
                        seteditcolourPatternFilter(v.id);
                        setPattern(v.name);
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button onClick={() => deleteColourPattern(v.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
