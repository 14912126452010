import React, { useState, useEffect} from "react";
import { Box, Button, Container, Grid, Typography, Paper, Card, CardContent, CardMedia } from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import { collection, query, where, getDocs, limit, startAfter, limitToLast, orderBy, endBefore } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase/firebase-config"
import LoadingPage from "../../pages/LoadingPage";

const ProviderList = () => {
  const navigate = useNavigate()
  const [providers, setProviders] = useState([])

  const [loadingProvider, setLoadingProvider] = useState(true)

  useEffect(()=>{
    const fetchProviders = async () => {
      setLoadingProvider(true)
      try {
        const q = query(collection(db, "Brands"), limit(12));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
        });
        setProviders(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

      } catch (error) {
        console.log(error)
      } finally {
        setLoadingProvider(false)
      }
    }
    fetchProviders()
  }, [])

  const BrandCard = ({ brand }) => {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
      const fetchImage = async () => {
        if (brand.id) {
          try {
            const storage = getStorage();
            const storageRef = ref(storage, `Brands/${brand.id}/logo.png`);
            const url = await getDownloadURL(storageRef);
            // alert(url)
            setImageUrl(url)
          } catch (error) {
            // Handle any errors
            console.error('Error downloading image:', error);
          }
        }
      };
  
      fetchImage();
    }, [brand]);
  
    return (
      <Grid item xs={12} md={6}>
        {/* {JSON.stringify(brand)} */}
        <Card component={Link} to={`/p/${brand.id}`} elevation={6} sx={{ 
          display: 'flex', flexDirection: 'row', alignItems: 'center',
          height: 120, marginBottom: 2, justifyContent: "space-between",
          textDecoration: "none"
        }}>
          <CardContent sx={{ flexBasis: '70%', display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" noWrap={false}>
              {brand.name}
            </Typography>
          </CardContent>
          <img src={imageUrl} alt={brand.brand} height="120px" width="120px" sx={{flexBasis: '30%'}} />
        </Card>
      </Grid>
    )
  }  

  return (
    <Box>
      <Box
        px={4}
        py={6}
        sx={{
          backgroundColor: '#232326',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        {/* <Typography variant="h2" sx={{ fontWeight: 'bold', marginBottom: 2, color: '#f0f0f0' }}>
          View Products by Brands
        </Typography>
        <Typography variant="body1" sx={{ fontSize: '1.2rem', marginBottom: 4, color: '#f0f0f0' }}>
          Explore our diverse collection from various brands. Find the perfect products that suit your preferences.
        </Typography> */}
        <Typography variant="h2" sx={{ backgroundColor: '#232326', fontWeight: 'bold', marginBottom: 2, color: '#f0f0f0' }}>
          Explore Brands
        </Typography>

      </Box>

      {loadingProvider?
      <LoadingPage/>:
      <Container sx={{py: 5}}>
        <Box px={8} component={Grid} container spacing={2}>
          {providers.map((brand, index) => (
            <BrandCard key={index} brand={brand} />
          ))}
        </Box>
      </Container>
      }

    </Box>
  );
};

export default ProviderList;
