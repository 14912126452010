import React, { useState, useEffect, useContext } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom';
import AdminBar from './adminBar';
import AdminSideBar from './adminSideBar';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from '../../../firebase/firebase-config';
import TopBar from '../../../components/layout/appbar';
import Footer from '../../../components/layout/footer';
import { Button, Container, Typography, Box, Snackbar, Alert } from '@mui/material';
import {AuthContext} from "./authCtx";
import LoadingPage from "../../LoadingPage"

const AdminLayout = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(null);
  const [superadmin, setSuperAdmin] = useState(false)
  const [supers, setSupers] = useState([])
  const [userId, setUserId] = useState('');
  const [user, setUser] = useState(null)
  const [displayName, setDisplayName] = useState('');
  const [photoURL, setPhotoURL] = useState('');
  const [loading, setLoading] = useState(true)

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setUser(user)
        setDisplayName(user.displayName || '');
        setPhotoURL(user.photoURL || '');
        setAuthenticated(true);
        setUserId(uid);
        setSuperAdmin(false)
        setSnackbarMessage('Logging in your admin account!');
        setSnackbarOpen(true);  
      } else {
        setAuthenticated(false);
        setUser(null)
        setSuperAdmin(false)
      }
    });

    return () => {
      unsubscribe(); // Cleanup the subscription when the component unmounts
    };
  }, []);

  useEffect(() => {   
    const superAuthTest = async () => {
      // Assuming you have a reference to your Firebase document
      const docRef = doc(db, "Authorization", "Super-Admins");
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        console.log("Supers:", docSnap.data());
        setSupers(docSnap.data().superadmins)
        if (docSnap.data().superadmins.includes(user.email)) {
          setSuperAdmin(true)
          setSnackbarMessage('Logging you in as Super Admin!');
          setSnackbarOpen(true);  
        } else {
          setSuperAdmin(false)
        }
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
        setSuperAdmin(false)
      }
    };

    if (user) superAuthTest()

  }, [user, authenticated])
  
  if (authenticated==null) {
    return (
      <LoadingPage/>
    )
  }

  return (
    <Box>
      <AuthContext.Provider value={{authenticated, superadmin, loading, setLoading, userId}}>
        {authenticated?
          <Box style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <AdminBar />
          <Box style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
            <AdminSideBar />
            <Box px={2} sx={{width: "100%", overflowX: "auto"}}>
              {children} 
              <Outlet />
            </Box>
          </Box>
          </Box>:
          <Box>
            <TopBar/>
            <NotAuthorizedPage/>
            <Footer/>
          </Box> 
        }
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity="success"
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

      </AuthContext.Provider>
    </Box>
  );
};

export default AdminLayout;

const NotAuthorizedPage = () => {
  const navigate = useNavigate();

  return (
    <Container style={{ textAlign: 'center', marginTop: '50px', padding: "150px", overflowX: "auto" }}>
      <Typography variant='h1' sx={{fontWeight: "bold", color: "red"}}>Not Authorized</Typography >
      <Typography my={3} variant='h5'>Please log in to access this page.</Typography>
      <Button color='success' variant='contained' onClick={() => navigate('/login')}>Go to Login</Button>
    </Container>
  );
};
