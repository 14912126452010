import React, {useContext, useState} from "react"
import { 
    AppBar, Toolbar, Typography, Box, Divider, List, ListItem, ListItemButton,
    ListItemText, CssBaseline, IconButton, Button, Drawer
} from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, Link } from "react-router-dom";
import { ProviderContext } from "./providerCtx";

const TopBar = () => {
    const { brandName, setBrandName, brandImg, setBrandImg, brandId, setBrandId } = useContext(ProviderContext)
    const navigate = useNavigate()
    const drawerWidth = 240;
    const navItems = [
        {name: 'Stone Range', path: `p/${brandId}/stones`},
        {name: 'Contact', path: '/contact'}
    ]
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
        <Typography variant="h6"  sx={{ my: 2, textDecoration: "none", color: "black" }}>
        {brandName?brandName:"Quote Providers Hub"}
        </Typography>
        <Divider />
        <List>
        {navItems.map((item) => (
            <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} component={Link} to={item.path}>
                <ListItemText primary={item.name} />
            </ListItemButton>
            </ListItem>
        ))}
        </List>
    </Box>
    );

    // const container = window !== undefined ? () => window().document.body : undefined;

    return (
    <Box sx={{ display: 'flex' }}>
        {/* <CssBaseline /> */}
        <AppBar position="static" component="nav">
        <Toolbar variant="dense" sx={{backgroundColor: "#232323"}}>
            <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
            >
                <MenuIcon />
                <Typography ml={2} variant="h6">
                {brandName?brandName:"Quote Providers Hub"}
                </Typography>
            </IconButton>
            <Typography
            variant="h6" component={Link} to={`p/${brandId}`}
            // component="div"
            sx={{ textDecoration: "none", color: "white", flexGrow: 1, display: { xs: 'none', sm: 'block' }, cursor: "pointer" }}
            onClick={()=>navigate('/')}
            >
            {brandName?brandName:"Quote Providers Hub"}
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
                <Button key={item.name} sx={{ color: '#fff' }} component={Link} to={item.path}>
                {item.name}
                </Button>
            ))}
            </Box>
        </Toolbar>
        </AppBar>
        <nav>
        <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
        >
            {drawer}
        </Drawer>
        </nav>
        </Box>
    );

}

export default TopBar