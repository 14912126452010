import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, TextField, Button, List, ListItem, ListItemText, IconButton, Snackbar } from '@mui/material';
import { doc, getDoc, setDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from "../../../firebase/firebase-config";
import DeleteIcon from '@mui/icons-material/Delete';

const SuperAdminManagement = () => {
  const [superAdmins, setSuperAdmins] = useState([]);
  const [newAdminEmail, setNewAdminEmail] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchSuperAdmins = async () => {
      const docRef = doc(db, 'Authorization', 'Super-Admins');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log('Supers:', docSnap.data());
        setSuperAdmins(docSnap.data().superadmins || []);
      } else {
        console.log('No such document!');
      }
    };

    fetchSuperAdmins();
  }, []);

  const addAdmin = async () => {
    // Add a new admin to the list and update the database
    const docRef = doc(db, 'Authorization', 'Super-Admins');
  
    // Update the database
    await setDoc(docRef, { superadmins: arrayUnion(newAdminEmail) }, { merge: true });

        
    // Update the local state
    setSuperAdmins((prevAdmins) => [...prevAdmins, newAdminEmail]);

    setNewAdminEmail('');
    setSnackbarMessage(`Added ${newAdminEmail} as a Super Admin.`);
    setSnackbarOpen(true);
  };
  
  const removeAdmin = async (adminEmail) => {
    // Remove an admin from the list
    const docRef = doc(db, 'Authorization', 'Super-Admins');
    await setDoc(docRef, { superadmins: arrayRemove(adminEmail) }, { merge: true });
    setSuperAdmins((prevAdmins) => prevAdmins.filter((admin) => admin !== adminEmail));

    setSnackbarMessage(`Removed ${adminEmail} from Super Admins.`);
    setSnackbarOpen(true);
  };

  return (
    <Container>
      <Typography variant="h4" sx={{ marginTop: 2, marginBottom: 4 }}>
        Super Admin Management
      </Typography>

      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h6">Existing Super Admins:</Typography>
        <List>
          {superAdmins.map((admin) => (
            <ListItem key={admin}>
              <ListItemText primary={admin} />
              {superAdmins.length > 1 && (
                <IconButton onClick={() => removeAdmin(admin)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </ListItem>
          ))}
        </List>
      </Box>

      <Box>
        <Typography variant="h6">Add New Super Admin:</Typography>
        <TextField
          fullWidth
          label="Email"
          variant="outlined"
          value={newAdminEmail}
          onChange={(e) => setNewAdminEmail(e.target.value)}
          error={snackbarMessage.includes('valid email')}
          helperText={snackbarMessage}
          sx={{ marginBottom: 2 }}
        />
        <Button variant="contained" color="primary" onClick={addAdmin}>
          Add Super Admin
        </Button>
      </Box>

      {/* Snackbar for showing messages */}
      {/* Adjust the Snackbar component based on your UI framework */}
      {/* Here, I'm assuming you have a Snackbar component */}
      {/* The Snackbar should close after a short delay */}
      {/* You can use a library like MUI Snackbar or implement your own */}
      {/* https://mui.com/components/snackbars/ */}
      {snackbarOpen && (
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
        />
      )}
    </Container>
  );
};

export default SuperAdminManagement;
