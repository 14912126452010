import React, { useEffect, useRef } from "react";

const useDragger = (id) => {
  const isClicked = useRef(false);
  const coords = useRef({ startX: 0, startY: 0, lastX: 0, lastY: 0 });

  useEffect(() => {
    const target = document.getElementById(id);
    if (!target) throw new Error("Element with given id doesn't exist");

    const container = target.parentElement;
    if (!container) throw new Error("target must have a parent");

    const onStart = (clientX, clientY) => {
      isClicked.current = true;
      coords.current.startX = clientX;
      coords.current.startY = clientY;
    };

    const onEnd = () => {
      isClicked.current = false;
      coords.current.lastX = target.offsetLeft;
      coords.current.lastY = target.offsetTop;
    };

    const onMove = (clientX, clientY) => {
      if (!isClicked.current) return;

      const nextX = clientX - coords.current.startX + coords.current.lastX;
      const nextY = clientY - coords.current.startY + coords.current.lastY;

      const maxX = container.clientWidth - target.clientWidth;
      const maxY = container.clientHeight - target.clientHeight;

      // Ensure the next position is within the boundaries
      const boundedX = Math.min(Math.max(nextX, 0), maxX);
      const boundedY = Math.min(Math.max(nextY, 0), maxY);

      target.style.top = `${boundedY}px`;
      target.style.left = `${boundedX}px`;
  
      // Use CSS classes
      target.style.transform = "translate3d(boundedX, boundedY, 0)"

  
    };

    
    const onMouseDown = (e) => onStart(e.clientX, e.clientY);
    const onTouchStart = (e) => {
      e.preventDefault()
      const touch = e.touches[0];
      onStart(touch.clientX, touch.clientY);
    };

    const onMouseUp = () => onEnd();
    const onTouchEnd = () => onEnd();

    const onMouseMove = (e) => onMove(e.clientX, e.clientY);
    const onTouchMove = (e) => {
      e.preventDefault()
      const touch = e.touches[0];
      onMove(touch.clientX, touch.clientY);
    };

    target.addEventListener("mousedown", onMouseDown);
    target.addEventListener("touchstart", onTouchStart, { passive: true });

    container.addEventListener("mouseup", onMouseUp);
    container.addEventListener("touchend", onTouchEnd);

    container.addEventListener("mousemove", onMouseMove);
    container.addEventListener("touchmove", onTouchMove, { passive: true });

    const cleanup = () => {
      target.removeEventListener("mousedown", onMouseDown);
      target.removeEventListener("touchstart", onTouchStart);

      container.removeEventListener("mouseup", onMouseUp);
      container.removeEventListener("touchend", onTouchEnd);

      container.removeEventListener("mousemove", onMouseMove);
      container.removeEventListener("touchmove", onTouchMove);
    };

    return cleanup;
  }, [id]);

  return null;
};

export default useDragger;
