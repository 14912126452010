import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Paper,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
  limitToLast,
  orderBy,
  endBefore,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase/firebase-config";
import LoadingPage from "../../pages/LoadingPage";
import StoneCard from "../product_list/product";
import { ProviderContext } from "../layout/providerCtx";

const ProviderHome = () => {
  const { providerId } = useParams();

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [providers, setProviders] = useState([]);

  const [loadingProvider, setLoadingProvider] = useState(true);
  const [loading, setLoading] = useState(true);

  const { setBrandId } = useContext(ProviderContext);

  useEffect(() => {
    const updateProviderId = () => {
      setBrandId(providerId);
    };
    if (providerId !== null) updateProviderId();
  }, []);

  useEffect(() => {
    const fetchStones = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, "stones"),
          orderBy("created"),
          where("uploadedBy", "==", providerId),
          limit(12)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
        });
        setData(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchStones();
  }, []);

  return (
    <Box>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <Box
            px={4}
            py={6}
            sx={{
              backgroundColor: "#f0f0f0", // Light background color
              height: "80vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h2"
              sx={{ fontWeight: "bold", marginBottom: 2, color: "#333" }}
            >
              Design Your Stone Sketch Online and Get a Quote
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: "1.2rem", marginBottom: 4, color: "#555" }}
            >
              Choose from a variety of stone brands, sketch your design in our
              editor, and watch as your creation transforms into a real stone
              masterpiece. Get a personalized quote for your unique sketch now.
            </Typography>
            <Button
              onClick={() => navigate("stones")}
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "black" }}
            >
              Get a Quote
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              position: "relative",
              height: "100%",
              overflow: "hidden",
              display: { xs: "none", md: "block" }, // Hide image on small screens
            }}
          >
            {/* Replace the placeholder URL with the actual URL of your stock photo */}
            <img
              src="https://images.pexels.com/photos/2724748/pexels-photo-2724748.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" // Replace with the actual image URL
              alt="Stone Sketch"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box>
        {loading ? (
          <LoadingPage />
        ) : (
          <Box>
            <Box
              px={4}
              py={6}
              sx={{
                backgroundColor: "#232326",
                // backgroundColor: "#f0f0f0",
                // height: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="h2"
                sx={{ fontWeight: "bold", marginBottom: 2, color: "#f0f0f0" }}
              >
                View Our Latest Stones
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "1.2rem", marginBottom: 4, color: "#f0f0f0" }}
              >
                Explore our newest stone arrivals and discover the latest trends
                in stone design. Don't miss out on the beauty of our latest
                collections.
              </Typography>
            </Box>

            <Box sx={{ backgroundColor: "#232326" }}>
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  // backgroundColor: '#d3d3d3',
                  // backgroundColor: '#232326',
                  paddingBottom: 8,
                }}
              >
                {data &&
                  data.map((stone) => {
                    return (
                      <StoneCard
                        id={stone.id}
                        key={stone.id}
                        name={stone.name}
                        image={stone.image}
                        width={stone.width}
                        size={stone.size}
                        collection={stone.range}
                        Tones={stone.Tones}
                        price={stone.price}
                        stone={stone}
                      />
                    );
                  })}
              </Container>
            </Box>

            <Box
              px={4}
              py={10}
              sx={{
                backgroundColor: "#f0f0f0",
                // height: "80vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // alignItems: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{ fontWeight: "bold", marginBottom: 2, color: "#333" }}
              >
                View More Products
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "1.2rem", marginBottom: 4, color: "#555" }}
              >
                Explore our complete collection of high-quality stones. Click
                below to view more products and find the perfect stone for your
                project.
              </Typography>
              <Button
                onClick={() => navigate(`/p/${providerId}/stones`)}
                variant="contained"
                color="primary"
                sx={{ backgroundColor: "black" }}
              >
                View More
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProviderHome;
