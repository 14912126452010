import { 
    Typography, Box, TableContainer, Table, TableHead,
    TableBody, TableRow, TableCell, Paper, Button, Snackbar, Stack
} from "@mui/material"
import React, {useEffect, useState, useContext} from 'react';
import { collection, getDocs, limit, query, startAfter, deleteDoc, doc, where, orderBy } from "firebase/firestore";
import { db } from "../../../firebase/firebase-config";
import {AuthContext} from "../layout/authCtx"
import LoadingPage from "../../LoadingPage";
import { Link } from "react-router-dom";

const pageSize = 40

const AdminQuotes = () => {
    const { authenticated, loading, setLoading, userId, superadmin } = useContext(AuthContext);
    const [data, setData] = useState([])
    const [quotes, setQuotes] = useState([])
    const [lastDoc, setLastDoc] = useState(null)
    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const dynamicQ = superadmin
    ? query(collection(db, "Quotes"), orderBy('quoteId', 'desc'), limit(pageSize))
    : query(collection(db, "Quotes"), orderBy('quoteId', 'desc'), where("provider", "==", userId), limit(pageSize));

    useEffect(() => {
        setLoading(true);
        const fetchQuotes = async () => {
            try {
                const querySnapshot = await getDocs(dynamicQ);
                setQuotes(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setData(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
            } catch (error) {
                console.error('Error fetching quotes:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchQuotes();
    }, []);
    
    const handleNext = async () => {
        setLoading(true);
    
        try {
            const nextQuerySnapshot = await getDocs(query(dynamicQ, startAfter(lastDoc), limit(pageSize)));
            const newQuotes = nextQuerySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    
            setQuotes(newQuotes);
            setData((prevData) => [...prevData, ...newQuotes]);
            setLastDoc(nextQuerySnapshot.docs[nextQuerySnapshot.docs.length - 1]);
        } catch (error) {
            console.error('Error fetching next quotes:', error);
        } finally {
            setLoading(false);
        }
    };
          
    const handleClose = () => {
        setOpen(false);
    };

    const deleteQuote = async (doc_id) => {
        try {
            await deleteDoc(doc(db, "Quotes", doc_id));
            console.log("Deleted document")
            setSnackbarMessage('Quote deleted successfully');
            setOpen(true)
            const updatedItems = data.filter(item => item.id !== doc_id);
            setData(updatedItems);
          
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>
                Quotes
            </Typography>

            {
                loading?
                <LoadingPage/>:
                <TableContainer component={Paper} sx={{overflowX: "scroll"}}>
                    <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell>Customer Email</TableCell>
                        <TableCell>Invoice Number</TableCell>
                        <TableCell>View</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data.length>0 ? data.map((quote) => (
                        <TableRow key={quote.id}>
                            <TableCell>{quote.customerId}{' '}{quote.lastName}</TableCell>
                            <TableCell>{quote.quoteId}</TableCell>
                            <TableCell component={Stack} direction="row" spacing={2}>
                                <Button component={Link} to={`/quote/view/${quote.id}`} color="success" variant="contained">View</Button>
                                <Button onClick={()=>deleteQuote(quote.id)} color="error" variant="contained">Delete</Button>
                            </TableCell>
                        </TableRow>
                        )) :
                        "Not Enough quotes"}
                    </TableBody>
                    </Table>
                </TableContainer>
            }

            <Box>
                <Button disabled={loading || quotes.length<pageSize} variant='contained' onClick={handleNext}>
                {loading?"Loading":"Load Next"}
                </Button>
            </Box>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={open}
                autoHideDuration={6000} // Adjust the duration as needed
                onClose={handleClose}
                message={snackbarMessage}
            />


        </Box>
    )
}

export default AdminQuotes
