import React, {useState} from "react"
import { 
    AppBar, Toolbar, Typography, Box, Divider, List, ListItem, ListItemButton,
    ListItemText, CssBaseline, IconButton, Button, Drawer
} from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, Link } from "react-router-dom";

const AdminBar = () => {
    const navigate = useNavigate()
    return (
        <AppBar position="static" color="primary">
            <Toolbar variant="dense" sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography variant="h6" component={Link} to="/admin/dashboard" sx={{color: "white", textDecoration: "none", marginRight: "10px"}}>
                Admin Panel
                </Typography>
                <Button variant="contained" color= "secondary" component={Link} to={"/"} sx={{textDecoration: "none"}}>
                Visit Website
                </Button>
            </Toolbar>
        </AppBar>
    )

}

export default AdminBar