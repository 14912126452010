import React, { useState, useEffect, useContext } from "react"
import { 
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
    Stack, Button, Snackbar 
} from "@mui/material"
import { 
    collection, query, where, getDocs, limit, startAfter, getCountFromServer,
    deleteDoc, doc, orderBy
} from "firebase/firestore";
import { getStorage, ref, listAll, deleteObject } from "firebase/storage";
import { db } from '../../../firebase/firebase-config';
import { Link } from 'react-router-dom';
import {AuthContext} from "../layout/authCtx";
import LoadingPage from "../../LoadingPage";

const storage = getStorage()

const pageSize = 40

const AdminStones = () => {
    const { authenticated, loading, setLoading, superadmin, userId } = useContext(AuthContext);
    const [stones, setStones] = useState([]);
    const [data, setData] = useState([]);
    const [lastDoc, setLastDoc] = useState(null);

    const fetchStones = async (query) => {
        setLoading(true);
        try {
          const querySnapshot = await getDocs(query);
          const newStones = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      
          // Use a Map to ensure unique elements based on the "id" property
          setData((prevData) => {
            const newDataMap = new Map([...prevData.map((item) => [item.id, item]), ...newStones.map((item) => [item.id, item])]);
            return Array.from(newDataMap.values());
          });
      
          setStones(newStones);
          setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        } catch (error) {
          console.error('Error fetching stones:', error);
        } finally {
          setLoading(false);
        }
    };
                  
    const dynamicQ = superadmin
    ? query(collection(db, 'stones'), orderBy('created', "desc"), limit(pageSize))
    : query(collection(db, 'stones'), where('uploadedBy', '==', userId), orderBy('created', "desc"), limit(pageSize))

    useEffect(() => {
      fetchStones(dynamicQ);
    }, []);
  
    const handleNext = async () => {
        if (lastDoc) {
          const nextQuery = query(dynamicQ, startAfter(lastDoc));
          fetchStones(nextQuery);
        }
    };
      
    return (
        <Box sx={{width: "100%", p: 2}}>
            <Box>
                <Button variant="contained" component={Link} to="/admin/add-stone">Create New Stone</Button>
            </Box>
            <Box>
                {
                    loading?
                    <LoadingPage/>:
                    <TableContainer sx={{width: "60vw"}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {["Name", "Brand", "Range", "Actions"].map((i)=>{
                                        return <TableCell>{i}</TableCell>
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data && data.map((stone)=>{
                                    return (
                                        <StonesInRow stone={stone} stones={data} setStones={setStones} setData={setData}/>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Box>

            <Box>
                <Button disabled={loading || stones.length<pageSize} variant='contained' onClick={handleNext}>
                {loading?"Loading":"Load Next"}
                </Button>
            </Box>
        </Box>
    )
}

const StonesInRow = ({stone, stones, setStones, setData}) => {
    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
  
    async function deleteFilesInDirectory(directoryPath) {
        // Create a reference to the directory
        const directoryRef = ref(storage, directoryPath);
      
        try {
          // List all files in the directory
          const files = await listAll(directoryRef);
      
          // Delete each file in the directory
          await Promise.all(files.items.map(async (file) => {
            await deleteObject(file);
            console.log(`Deleted file: ${file.name}`);
          }));
      
          console.log(`All files in ${directoryPath} deleted successfully.`);
        } catch (error) {
          console.error('Error deleting files:', error);
        }
    }
      
    const handleClose = () => {
        setOpen(false);
    };
    
    const deleteStone = async (doc_id) => {
        try {
            deleteFilesInDirectory(doc_id)
            console.log("Deleting images")
            setSnackbarMessage('Images deleted successfully');

            setOpen(true);

            await deleteDoc(doc(db, "stones", doc_id));
            console.log("Deleted document")
            setSnackbarMessage('Stone deleted successfully');
            const updatedItems = stones.filter(item => item.id !== doc_id);
            setData(updatedItems);
          
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <TableRow>
            <TableCell>{stone.name}</TableCell>
            <TableCell>{stone.brand}</TableCell>
            <TableCell>{stone.range}</TableCell>
            <TableCell align="right">
                <Stack direction="row" spacing={1}>
                    <Button component={Link} to={`/stones/${stone.id}`} color="success" variant="contained">View</Button>
                    {/* <Button color="warning" variant="contained">Edit</Button> */}
                    <Button onClick={()=>deleteStone(stone.id)} color="error" variant="contained">Delete</Button>
                </Stack>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={open}
                    autoHideDuration={6000} // Adjust the duration as needed
                    onClose={handleClose}
                    message={snackbarMessage}
                />
            </TableCell>
        </TableRow>

    )
}

export default AdminStones