import { Container, Modal, Box, Button, TextField, Typography } from "@mui/material"
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Checkbox, Autocomplete } from "@mui/material"
import React, { useState, useRef, useEffect } from "react"
import useDragger from "./useDragger"
import {useNavigate} from "react-router-dom"

const StoneNew = ({h, w, id}) => {
    let uid = id + (new Date()).getTime();
    
    useDragger(uid)
    const translatedWidth = w/10
    const translatedHeight = h/10
    return(
        <div id={uid} className="box" style={{
            height: `${translatedHeight}px`, width: `${translatedWidth}px`, 
            // backgroundColor: 'white', opacity: 0.6, 
            backgroundColor: 'rgba(10, 100, 10, 0.5)', color: 'black',
            border: '1px solid black', 
            position: 'absolute', cursor: 'pointer',
            display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column'
        }}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                {/* <p>{id}</p> */}
                <p>{id} = {h}mm x {w}mm</p>
                {/* <p>{h}mm</p> */}
                {/* <p>x</p> */}
                {/* <p>{h}mm</p> */}
            </div>
        </div>
    )
}

const GridCanvas = (props) => {
    const [slabs, setSlabs] = useState(1)
    const onAddBtnClick = () => {
        console.log(`Div data recieved, ${'1000'}, ${'2000'}`)
        props.setDivList(props.divList.concat(<StoneNew h={'100'} w={'100'} id={'name'}/>));
    };

    const handleSlabs = (e) => {
        const val = e.target.value
        if (Number(val)>=1){
            setSlabs(e.target.value)
        } else {
            setSlabs(1)
            alert("Number of Slabs to purchase can not be 0 or less than 0.")
        }                
    }

    const itemsArray = Array.from({ length: slabs });

    return (
        <>
        <Box sx={{my:2}}>
            <TextField
                id="number-of-slabs" onChange={handleSlabs}
                label="Number of Slabs" variant="outlined"
                type="number" size="small" inputProps={{ min: 1 }}
            />

        </Box>

        {/* <Button onClick={onAddBtnClick} variant="contained">Add Stone to Sketch</Button> */}
        <div id="parent_slabs" className="container" 
            style={{
                backgroundColor: "gray",
            // backgroundImage: "url('/grid/grid1.jpg')", backgroundRepeat:"repeat", 
            backgroundPosition: 'center',
            backgroundSize: '400px', opacity: 0.8,
            height: '100%', minWidth: '1000px', width: "100%", margin: 'auto', 
            position: 'relative', overflow: 'hidden',
            border: "1px solid black",
            display: "flex", flexDirection: "row"
        }}
        >
            <div>
                {itemsArray.map((_, index) => (                    
                    <div key={index} style={{backgroundColor: "rgba(200, 200, 200, 1)", width: `${400}px`, height: '200px', border: "2px solid black", borderCollapse: "collapse"}}></div>
                ))}
                
            </div>
            <div style={{
                backgroundColor: "rgba(100, 100, 100, 1)", 
                width: `${1000-400}px`, minHeight: "360px", height: "100%",
                display: 'flex', gap: '50px', flexDirection: "column"
            }}>
                {props.divList.map((div, index) => (
                    <div key={index}>
                        {div}
                    </div>
                ))}
            </div>                
            {/* {props.divList} */}
            </div>
        </>
    )
}

const StonePlacer = ({stones, data}) => {
    const navigate = useNavigate()
    const [divList, setDivList] = useState([]);
    const [install, setInstall] = useState(true)

    console.log("Stones: ")
    console.log(stones)

    useEffect(()=>{        
        const createNewObjects = () => {
            const newStones = stones.map(stone => <StoneNew h={stone.height} w={stone.width} id={stone.name}/>);
            setDivList(prevStones => prevStones.concat(newStones));
        };

        createNewObjects()
        }, [])

    return (
        <Box p={2}>
            <GridCanvas divList={divList} setDivList={setDivList}/>
            {/* installed? */}
            {/* <FormControlLabel 
                control={<Checkbox checked={install} onChange={()=>setInstall(!install)} />} 
                label="Do you also want to install this piece?" 
            /> */}
            <Typography onClick={()=>navigate('/quote/address', {state: {data}})} sx={{backgroundColor: "black", color: 'white', p:2, my:2, textAlign: "center", width: "fit-content", cursor: "pointer"}}>Proceed to Quote</Typography>
        </Box>

    )
}

export default StonePlacer
