import {
  Container,
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Stack,
  Radio,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState, useRef, useEffect, useContext } from "react";
// import useDragger from "./useDragger"
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase-config";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

let imgURL =
  "https://media.istockphoto.com/id/1273109788/vector/coming-soon-isolated-vector-icon-paper-style-promotion-sign-start-a-new-business-design.jpg?s=612x612&w=0&k=20&c=0APH6QCc371SuCEYLspgp6oh-tE5-rvbK0dzLMRmJGA=";

const edge_types = [
  {
    value: "Single Edge",
    label: "Single Edge",
    price: 1,
  },
  {
    value: "Double Edge",
    label: "Double Edge",
    price: 1.5,
  },
  {
    value: "Mitred Apron",
    label: "Mitred Apron",
    price: 2,
  },
];

const edge_profiles = [
  {
    value: "Pencil",
    label: "Pencil",
    price: 20,
  },
  {
    value: "Bull Nose",
    label: "Bull Nose",
    price: 100,
  },
  {
    value: "Shark Nose",
    label: "Shark Nose",
    price: 100,
  },
  {
    value: "Splayed",
    label: "Splayed",
    price: 100,
  },
];

const sink_cutouts = [
  { value: "None", label: "None", price: 0 },
  { value: "Drop In Sink", label: "Drop In Sink", price: 20 },
  {
    value: "Undermount Single Bowl",
    label: "Undermount Single Bowl",
    price: 100,
  },
  {
    value: "Undermount Double Bowl",
    label: "Undermount Double Bowl",
    price: 200,
  },
  // { value: 'undermount_twin_polished', label: 'Undermount Twin Sinks (Polished Cut Out)', price: 20 },
  // { value: 'flushmount_polished', label: 'Flushmount Sink (Polished Cut Out)', price: 20 }
];

const cooktop_cutouts = [
  { value: "None", label: "None", price: 0 },
  { value: "Drop In Cooktop", label: "Drop In Cooktop", price: 20 },
  { value: "Flushmount Cooktop", label: "Flushmount Cooktop", price: 200 },
];

const other_variables = [
  { value: "waterfall", label: "Waterfall", price: 200 },
  { value: "splashback", label: "Splash Back", price: 50 },
  { value: "holes", label: "Holes", price: 5 },
];

const splashCategs = [
  { value: "Yes", label: "Yes", price: 200 },
  { value: "No", label: "No", price: 0 },
];

const WaterWallCategs = [
  { value: "Yes", label: "Yes", price: 200 },
  { value: "No", label: "No", price: 0 },
];

const stone_categories = [
  "Bench Top Piece",
  "Island or Bar Piece",
  "Drop Panel Waterfall",
  "Splash Back Piece",
];

const extract_price_from_db = (data, category, desiredLabel) =>
  ((selectedItem) =>
    selectedItem !== undefined ? parseFloat(selectedItem) || 0 : null)(
    data[category]?.[desiredLabel]
  );

const extract_label = (data, desiredValue) => {
  const selectedItem = data.find(
    (item) => item.label === desiredValue || item.value === desiredValue
  );

  if (selectedItem) {
    const label = selectedItem.label;
    console.log(`Label for value '${desiredValue}' is '${label}'`);
    return label;
  } else {
    console.log(`Value '${desiredValue}' not found in the array.`);
  }

  return null;
};

const StoneDetailCatcher = (props) => {
  const [edgeType, setEdgeType] = useState(0);
  const [edgeProfile, setEdgeProfile] = useState(0);
  const [sinkCut, setSinkCut] = useState(0);
  const [cookTopCut, setCookTopCut] = useState(0);

  const [waterwallBool, setWaterWallBool] = useState(false);
  const [splashbackBool, setSplashbackBool] = useState(false);
  const [waterwall, setWaterWall] = useState(0);
  const [splashback, setSplashback] = useState(false);
  const [holes, setholes] = useState(0);
  const [holesNo, setholesNo] = useState(0);

  const [price, setPrice] = useState(0);

  const [slabWidth, setSlabWidth] = useState(0);
  const [slabDepth, setSlabDepth] = useState(0);

  const [pieceWidth, setPieceWidth] = useState(0);
  const [pieceDepth, setPieceDepth] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [slabPrice, setSlabPrice] = useState(0);

  useEffect(() => {
    const slabArea = parseInt(slabWidth * slabDepth);
    const quantity_proportion = (pieceWidth * pieceDepth) / slabArea;
    const roundedQuantity =
      quantity_proportion % 1 > 0.45
        ? Math.ceil(quantity_proportion)
        : quantity_proportion;
    const roundedQuantityWithDecimal = Number(roundedQuantity.toFixed(2));

    setQuantity(roundedQuantityWithDecimal);
  }, [pieceWidth, pieceDepth, slabWidth, slabDepth]);

  useEffect(() => {
    const price =
      Number(Math.ceil(quantity)) * Number(slabPrice) +
      2 * (edgeType * pieceDepth) +
      2 * (edgeType * pieceWidth) +
      2 * (edgeProfile * pieceDepth) +
      2 * (edgeProfile * pieceWidth) +
      sinkCut +
      cookTopCut +
      Number(holesNo * holes) +
      Number(waterwall) +
      Number(splashback);

    console.log("price of this much");
    console.log(price);

    setPrice(price);
  }, [
    edgeType,
    edgeProfile,
    sinkCut,
    cookTopCut,
    holes,
    holesNo,
    waterwall,
    waterwallBool,
    splashback,
    splashbackBool,
    pieceDepth,
    pieceWidth,
  ]);

  return (
    <Box mt={5}>
      <Typography
        variant="h6"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        Price Calculator (Quote Algorithm)
      </Typography>
      <Box>
        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ flexBasis: "10%" }}>Slab Dimensions (mm)</Box>
          <Box sx={{ flexBasis: "30%" }}>
            <TextField
              label={"Slab Width"}
              fullWidth
              variant="standard"
              type="number"
              onChange={(e) => setSlabWidth(Number(e.target.value))}
            ></TextField>
          </Box>
          <Box sx={{ flexBasis: "30%" }}>
            <TextField
              label={"Slab Depth"}
              fullWidth
              variant="standard"
              type="number"
              onChange={(e) => setSlabDepth(Number(e.target.value))}
            ></TextField>
          </Box>
          <Box sx={{ flexBasis: "18.5%" }}>
            <TextField
              label={"Slab Price"}
              fullWidth
              variant="standard"
              type="number"
              onChange={(e) => setSlabPrice(Number(e.target.value))}
            ></TextField>
          </Box>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ flexBasis: "10%" }}>Piece Dimensions</Box>
          <Box sx={{ flexBasis: "40%" }}>
            <TextField
              label={"Piece Width (mm)"}
              fullWidth
              variant="standard"
              type="number"
              onChange={(e) => setPieceWidth(Number(e.target.value))}
            ></TextField>
          </Box>
          <Box sx={{ flexBasis: "40%" }}>
            <TextField
              label={"Piece Depth (mm)"}
              fullWidth
              variant="standard"
              type="number"
              onChange={(e) => setPieceDepth(Number(e.target.value))}
            ></TextField>
          </Box>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ flexBasis: "10%" }}>Edge Type</Box>
          <Box sx={{ flexBasis: "30%" }}>
            <Select fullWidth dense variant="standard">
              {edge_types.map((i) => (
                <MenuItem value={i.label}>{i.label}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ flexBasis: "50%" }}>
            <TextField
              fullWidth
              variant="standard"
              placeholder={edgeType}
              type="number"
              onChange={(e) => setEdgeType(Number(e.target.value))}
            ></TextField>
          </Box>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ flexBasis: "10%" }}>Edge Profile</Box>
          <Box sx={{ flexBasis: "30%" }}>
            <Select fullWidth dense variant="standard">
              {edge_profiles.map((i) => (
                <MenuItem value={i.label}>{i.label}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ flexBasis: "50%" }}>
            <TextField
              fullWidth
              variant="standard"
              type="number"
              onChange={(e) => setEdgeProfile(Number(e.target.value))}
            ></TextField>
          </Box>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ flexBasis: "10%" }}>Sink Cut</Box>
          <Box sx={{ flexBasis: "30%" }}>
            <Select fullWidth dense variant="standard">
              {sink_cutouts.map((i) => (
                <MenuItem value={i.label}>{i.label}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ flexBasis: "50%" }}>
            <TextField
              fullWidth
              variant="standard"
              type="number"
              onChange={(e) => setSinkCut(Number(e.target.value))}
            ></TextField>
          </Box>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ flexBasis: "10%" }}>Cooktop Cutout</Box>
          <Box sx={{ flexBasis: "30%" }}>
            <Select fullWidth dense variant="standard">
              {cooktop_cutouts.map((i) => (
                <MenuItem value={i.label}>{i.label}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ flexBasis: "50%" }}>
            <TextField
              fullWidth
              variant="standard"
              type="number"
              onChange={(e) => setCookTopCut(Number(e.target.value))}
            ></TextField>
          </Box>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ flexBasis: "10%" }}>Waterfall</Box>
          <Box sx={{ flexBasis: "30%" }}>
            <Select fullWidth dense variant="standard">
              {WaterWallCategs.map((i) => (
                <MenuItem value={i.label}>{i.label}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ flexBasis: "50%" }}>
            <TextField
              fullWidth
              variant="standard"
              type="number"
              onChange={(e) => setWaterWall(Number(e.target.value))}
            ></TextField>
          </Box>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ flexBasis: "10%" }}>Splashback</Box>
          <Box sx={{ flexBasis: "30%" }}>
            <Select fullWidth dense variant="standard">
              {splashCategs.map((i) => (
                <MenuItem value={i.label}>{i.label}</MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ flexBasis: "50%" }}>
            <TextField
              fullWidth
              variant="standard"
              type="number"
              onChange={(e) => setSplashback(Number(e.target.value))}
            ></TextField>
          </Box>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Box sx={{ flexBasis: "10%" }}>Holes</Box>
          <Box sx={{ flexBasis: "30%" }}>
            <TextField
              label="No. of Holes"
              fullWidth
              variant="standard"
              type="number"
              onChange={(e) => setholesNo(Number(e.target.value))}
            ></TextField>
          </Box>
          <Box sx={{ flexBasis: "50%" }}>
            <TextField
              label="Price for Hole"
              fullWidth
              variant="standard"
              type="number"
              onChange={(e) => setholes(Number(e.target.value))}
            ></TextField>
          </Box>
        </Stack>
      </Box>

      <Box p={2}>
        {/* <Typography variant="h6">
                Price = Rounded quantity of slab OR Slab Proportion ({quantity}) x Slab Price ({slabPrice}) +
                (
                    Price of selected edge type({edgeType}) + Price of selected edge profile({edgeProfile}) + 
                    Price of selected sinkcutout ({sinkCut}) + Price of sinkcutout ({cookTopCut}) +
                    Price of holes, if added ({Number(holesNo * holes)}) + 
                    Price of waterwall, if selected ({Number(waterwall * waterwallBool)}) + 
                    Price of waterwall, if selected ({Number(splashback * splashbackBool)}) 
                )
                </Typography> */}

        <Typography
          variant="h6"
          style={{
            marginTop: "16px",
            marginBottom: "16px",
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          Price = Rounded quantity of slab OR Slab Proportion (
          <span style={{ color: "red", fontWeight: "bold" }}>{quantity}</span>)
          x Slab Price (
          <span style={{ color: "red", fontWeight: "bold" }}>{slabPrice}</span>)
          + 2x (
          <span style={{ display: "block", marginTop: "8px" }}>
            Price of selected edge type (
            <span style={{ color: "red", fontWeight: "bold" }}>{edgeType}</span>
            ) x Piece Width (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {pieceWidth}
            </span>
            ) ) + 2x (Price of selected edge type (
            <span style={{ color: "red", fontWeight: "bold" }}>{edgeType}</span>
            )x Piece Depth(
            <span style={{ color: "red", fontWeight: "bold" }}>
              {pieceDepth}
            </span>
            ) ) + 2x (Price of selected edge profile (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {edgeProfile}
            </span>
            ) x Piece Depth (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {pieceDepth}
            </span>
            ) ) +2x( Price of selected edge profile (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {edgeProfile}
            </span>
            ) ) x Piece Width (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {pieceWidth}
            </span>
            ) + Price of selected sink cutout (
            <span style={{ color: "red", fontWeight: "bold" }}>{sinkCut}</span>)
            + Price of cooktop cutout (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {cookTopCut}
            </span>
            ) + Price of holes, if added (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {Number(holesNo * holes)}
            </span>
            ) + Price of waterfall, if selected (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {Number(waterwall * waterwallBool)}
            </span>
            ) + Price of splashback, if selected (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {Number(splashback * splashbackBool)}
            </span>
            )
          </span>
        </Typography>

        <Typography variant="h6">
          Price: <span style={{ color: "red" }}>{price}</span>
        </Typography>
      </Box>
    </Box>
  );
};

export default StoneDetailCatcher;
