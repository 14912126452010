import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Paper,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
  limitToLast,
  orderBy,
  endBefore,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { db } from "../firebase/firebase-config";
import LoadingPage from "./LoadingPage";
import StoneCard from "../components/product_list/product";

const Home = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [providers, setProviders] = useState([]);

  const [loadingProvider, setLoadingProvider] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStones = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, "stones"),
          orderBy("created"),
          limit(12)
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
        });
        setData(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchStones();
  }, []);

  useEffect(() => {
    const fetchProviders = async () => {
      setLoadingProvider(true);
      try {
        const q = query(collection(db, "Brands"), limit(12));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
        });
        setProviders(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingProvider(false);
      }
    };
    fetchProviders();
  }, []);

  const BrandCard = ({ brand }) => {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
      const fetchImage = async () => {
        if (brand.id) {
          try {
            const storage = getStorage();
            const storageRef = ref(storage, `Brands/${brand.id}/logo.png`);
            const url = await getDownloadURL(storageRef);
            // alert(url)
            setImageUrl(url);
          } catch (error) {
            // Handle any errors
            console.error("Error downloading image:", error);
          }
        }
      };

      fetchImage();
    }, [brand]);

    return (
      <Grid item xs={12} md={6}>
        {/* {JSON.stringify(brand)} */}
        <Card
          component={Link}
          to={`p/${brand.id}`}
          elevation={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: 120,
            marginBottom: 2,
            justifyContent: "space-between",
            textDecoration: "none",
          }}
        >
          <CardContent
            sx={{ flexBasis: "70%", display: "flex", alignItems: "center" }}
          >
            <Typography variant="h6" noWrap={false}>
              {brand.name}
            </Typography>
          </CardContent>
          <img
            src={imageUrl}
            alt={brand.brand}
            height="120px"
            width="120px"
            sx={{ flexBasis: "30%" }}
          />
        </Card>
      </Grid>
    );
  };

  return (
    <Box>
      {/* <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <Box
            px={4}
            py={6}
            sx={{
              backgroundColor: "#f0f0f0", // Light background color
              height: "80vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="h2" sx={{ fontWeight: "bold", marginBottom: 2, color: "#333" }}>
            Design Your Stone Sketch Online and Get a Quote
            </Typography>
            <Typography variant="body1" sx={{ fontSize: "1.2rem", marginBottom: 4, color: "#555" }}>
            Choose from a variety of stone brands, sketch your design in our editor, and watch as your creation transforms into a real stone masterpiece. Get a personalized quote for your unique sketch now.
            </Typography>
            <Button onClick={()=>navigate("stones")} variant="contained" color="primary" sx={{ backgroundColor: "#4caf50" }}>
              Get a Quote
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              position: "relative",
              height: "100%",
              overflow: "hidden",
              display: { xs: 'none', md: 'block' }, // Hide image on small screens
            }}
          >
            <img
              src="https://images.pexels.com/photos/2724748/pexels-photo-2724748.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" // Replace with the actual image URL
              alt="Stone Sketch"
              style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "8px" }}
            />
          </Box>
        </Grid>
      </Grid> */}

      {/* <Box>
        {loading?
        <LoadingPage/>:
        <Box>
          <Box
            px={4}
            py={6}
            sx={{
              backgroundColor: '#232326',
              // backgroundColor: "#f0f0f0",
              // height: "80vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Typography variant="h2" sx={{ fontWeight: "bold", marginBottom: 2, color: "#f0f0f0" }}>
              View Our Latest Stones
            </Typography>
            <Typography variant="body1" sx={{ fontSize: "1.2rem", marginBottom: 4, color: "#f0f0f0" }}>
              Explore our newest stone arrivals and discover the latest trends in stone design. Don't miss out on the beauty of our latest collections.
            </Typography>
          </Box>

          <Box sx={{backgroundColor: '#232326'}}>
            <Container sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              // backgroundColor: '#d3d3d3',
              // backgroundColor: '#232326',
              paddingBottom: 8
          }}>
              {
                  data && data.map((stone) => {
                      return (
                          <StoneCard 
                          id={stone.id}
                          key={stone.id}
                          name={stone.name} 
                          image={stone.image} 
                          width={stone.width} 
                          size={stone.size} 
                          collection={stone.range} 
                          Tones = {stone.Tones}
                          price={stone.price}
                          stone={stone}
                          />
                      )
                  })
              }
            </Container>
          </Box>

          <Box
            px={4}
            py={20}
            sx={{
            backgroundColor: "#f0f0f0",
            // height: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // alignItems: "flex-start",
            alignItems: "center",
            }}
            >
              <Typography variant="h2" sx={{ fontWeight: "bold", marginBottom: 2, color: "#333" }}>
              View More Products
              </Typography>
              <Typography variant="body1" sx={{ fontSize: "1.2rem", marginBottom: 4, color: "#555" }}>
              Explore our complete collection of high-quality stones. Click below to view more products and find the perfect stone for your project.
              </Typography>
              <Button onClick={() => navigate("/stones")} variant="contained" color="primary" sx={{ backgroundColor: "#4caf50" }}>
              View More
              </Button>
          </Box>
        </Box>}
      </Box> */}

      {loadingProvider ? (
        <LoadingPage />
      ) : (
        <Box>
          <Box
            px={4}
            py={6}
            sx={{
              backgroundColor: "#232326",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h2"
              sx={{ fontWeight: "bold", marginBottom: 2, color: "#f0f0f0" }}
            >
              Providers Working with us
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: "1.2rem", marginBottom: 4, color: "#f0f0f0" }}
            >
              Explore our diverse collection from various brands. Find the
              perfect products offered by various providers that suit your
              preferences.
            </Typography>

            <Container sx={{ py: 5 }}>
              <Box px={8} component={Grid} container spacing={2}>
                {providers.map((brand, index) => (
                  <BrandCard key={index} brand={brand} />
                ))}
              </Box>
            </Container>
          </Box>

          <Box
            px={4}
            py={10}
            sx={{
              backgroundColor: "#f0f0f0",
              // height: "80vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // alignItems: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h2"
              sx={{ fontWeight: "bold", marginBottom: 2, color: "#333" }}
            >
              View More Stone Providers
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: "1.2rem", marginBottom: 4, color: "#555" }}
            >
              Click below to view more providers and find the perfect stone for
              your project.
            </Typography>
            <Button
              onClick={() => navigate("/p")}
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "black" }}
            >
              View More
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Home;
