import React, { useState, useEffect,useContext } from 'react';
import { db } from '../../../firebase/firebase-config';
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Box,
  Typography,
} from '@mui/material';

import { getStorage } from 'firebase/storage';
import { doc, onSnapshot } from 'firebase/firestore';
import { AuthContext } from '../layout/authCtx';
import SuperAdminManagement from "../components/supermanage";
import ManageAdmins from "../auth/manage-admins";

const storage = getStorage();

const ManagementAsAdmins = () => {
  const navigate = useNavigate();
  const { authenticated, setAuthenticated, userId, setUserId, loading, setLoading, superadmin } = useContext(AuthContext);
  // const [authenticated, setAuthenticated] = useState(false)
  // const [userId, setUserId] = useState('')
  const [brandPrices, setBrandPrices] = useState([])

  useEffect(()=>{
    if (userId && userId!=='') {
      const unsub = onSnapshot(doc(db, "Brands", userId), (doc) => {
        console.log("Current data: ", doc.data());
        setBrandPrices(doc.data())
    });

    return () => unsub();
    } else {
      console.log("Not loaded userId")
    }
  }, [userId])

    return (
      <Box>
        {superadmin?
        <Box>
          <SuperAdminManagement/>
          <ManageAdmins/>
        </Box>:<NotAuthorizedPage/>}
      </Box>
    )
}

export default ManagementAsAdmins

const NotAuthorizedPage = () => {
    const navigate = useNavigate();
  
    return (
      <Container style={{ textAlign: 'center', marginTop: '50px', padding: "150px", overflowX: "auto" }}>
        <Typography variant='h1' sx={{fontWeight: "bold", color: "red"}}>Not Authorized</Typography >
        {/* <Typography my={3} variant='h5'>Please log in to access this page.</Typography> */}
        <Button color='success' variant='contained' onClick={() => navigate('/admin/dashboard')}>Go to Dashboard</Button>
      </Container>
    );
  };
  