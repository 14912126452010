// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore"
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC7Sk95DDNNlOz2C18ymgndF3-44qQW4Wk",
  authDomain: "stones-tanglay.firebaseapp.com",
  projectId: "stones-tanglay",
  storageBucket: "stones-tanglay.appspot.com",
  messagingSenderId: "116214082830",
  appId: "1:116214082830:web:6e625906ba37885ed63b5f",
  measurementId: "G-JW1368S9X3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);

