import React, {useState, useEffect, useContext} from 'react';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Stack, Snackbar } from '@mui/material';
import { collection, getDocs, limit, orderBy, deleteDoc, doc, query, startAfter, where } from "firebase/firestore";
import { db } from "../../../firebase/firebase-config";
import {AuthContext} from '../layout/authCtx';
import LoadingPage from '../../LoadingPage';
import { Link } from 'react-router-dom';

const pageSize = 40

const AdminCustomers = () => {
  const { authenticated, userId, loading, setLoading, superadmin } = useContext(AuthContext);
  const [data, setData] = useState([])
  const [customers, setCustomers] = useState([])
  const [lastDoc, setLastDoc] = useState(null)
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [finished, setFinished] = useState(false)

  let dynamicQ = superadmin
  ? query(collection(db, "Customers"), limit(pageSize))
  : query(collection(db, "Customers"), where("provider", "==", userId), limit(pageSize));

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        setLoading(true);
        const querySnapshot = await getDocs(dynamicQ);
        setData(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        setCustomers(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } catch (error) {
        console.error('Error fetching customers:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  const handleNext = async () => {
    try {
      setLoading(true);
      const nextQuerySnapshot = await getDocs(query(dynamicQ, startAfter(lastDoc), limit(pageSize)));
      const newCustomers = nextQuerySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      setCustomers(newCustomers);
      setData((prevData) => [...prevData, ...newCustomers]);
      setLastDoc(nextQuerySnapshot.docs[nextQuerySnapshot.docs.length - 1]);
      if (nextQuerySnapshot.docs.length < pageSize) setFinished(true)
    } catch (error) {
      console.error('Error fetching next customers:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteCustomer = async (doc_id) => {
    try {
        await deleteDoc(doc(db, "Customers", doc_id));
        console.log("Deleted document")
        setSnackbarMessage('Customer deleted successfully');
        setOpen(true)
        const updatedItems = data.filter(item => item.id !== doc_id);
        setData(updatedItems);
      
    } catch (error) {
        console.log(error)
    }
}

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Customers
      </Typography>

      <Box>
      {
        loading?
        <LoadingPage/>:
        <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{minWidth: 200}}>Name</TableCell>
              <TableCell sx={{minWidth: 200}}>Email</TableCell>
              <TableCell sx={{minWidth: 100}}>Phone</TableCell>
              <TableCell sx={{minWidth: 300}}>Billing Address</TableCell>
              <TableCell sx={{minWidth: 100}}>State Territory</TableCell>
              <TableCell sx={{minWidth: 100}}>Suburb</TableCell>
              <TableCell sx={{minWidth: 100}}>Country</TableCell>
              <TableCell sx={{minWidth: 50}}>Postcode</TableCell>
              <TableCell sx={{minWidth: 200}}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length>0 ? data.map((customer) => (
              <TableRow key={customer.id}>
                <TableCell>{customer.firstName}{' '}{customer.lastName}</TableCell>
                <TableCell>{customer.email}</TableCell>
                <TableCell>{customer.mobileNumber}</TableCell>
                <TableCell>{customer.billingAddress}</TableCell>
                <TableCell>{customer.stateTerritory}</TableCell>
                <TableCell>{customer.suburb}</TableCell>
                <TableCell>{customer.country}</TableCell>
                <TableCell>{customer.postcode}</TableCell>
                <TableCell>
                  <Stack direction={"row"} spacing={2}>
                    <Box sx={{minWidth: 150}}>
                      <Button component={Link} to={`mailto:${customer?.email}`} variant='contained' color='success'>Send Email</Button>
                    </Box>
                    <Box>
                      <Button onClick={() => deleteCustomer(customer.email)} variant='contained' color='error'>Delete</Button>
                    </Box>
                  </Stack>                    
                </TableCell>
              </TableRow>
            )) :
            "Not Enough Customers"}
          </TableBody>
        </Table>
        </TableContainer>
      }
      </Box>

      <Box>
        <Button disabled={loading || customers.length<pageSize} variant='contained' onClick={handleNext}>
          {loading?"Loading":"Load Next"}
        </Button>
      </Box>

      <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={open}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleClose}
          message={snackbarMessage}
      />

    </Box>
  );
};

export default AdminCustomers;
