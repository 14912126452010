import { Outlet } from "react-router-dom";
import TopBar from "./appbar";
import Footer from "./footer";
import { ProviderContext } from "./providerCtx";
import { useEffect, useState } from "react";
import { collection, query, where, getDocs, limit, startAfter, limitToLast, orderBy, endBefore, doc, getDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { db } from '../../firebase/firebase-config';
import { useLocation, useNavigate, useParams, NavLink } from "react-router-dom";

const UserLayout = () => {
    const [brandName, setBrandName] = useState("Quote Providers Hub")
    const [brandImg, setBrandImg] = useState(null)
    const [brandId, setBrandId] = useState(null)

    useEffect(() => {
        const fetchProvider = async () => {
            const docRef = doc(db, "Brands", brandId);
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) {
              console.log("Document data:", docSnap.data());
              setBrandName(docSnap.data().name)
            //   setBrandId(brandId)
            } else {
              // docSnap.data() will be undefined in this case
              console.log("No such document fr company name!");
            }
          
            try {
              const storage = getStorage();
              const storageRef = ref(storage, `Brands/${brandId}/logo.png`);
              const url = await getDownloadURL(storageRef);
      
              // alert(url)
              setBrandImg(url)
          
            } catch (error) {
              // Handle any errors
              console.error('Error downloading image:', error);
            }
        
        }
        if (brandId !== null) {
            // alert("alerting from layout brandId")
            // alert(brandId)
            fetchProvider()
        }
    }, [brandId])

    return (
        <div>
            <ProviderContext.Provider value={{
                brandName, setBrandName,
                brandImg, setBrandImg,
                brandId, setBrandId
            }}>
                <TopBar/>
                <Outlet/>
                {/* Test New Location of Footer */}
                <Footer />
            </ProviderContext.Provider>
        </div>
    )
}

export default UserLayout;