import React from "react"
import StoneFilter from "../components/product_list/list_stones"

const ListProduct = () => {
  return (
      <StoneFilter/>
    )
}


export default ListProduct