import React, {useEffect, useState, useContext} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Paper, Box, Snackbar, TextField, Button, Alert } from '@mui/material';
import {db, storage} from '../../../firebase/firebase-config'
import {collection, addDoc, Timestamp, doc, setDoc, deleteDoc} from 'firebase/firestore'
import { ref, getStorage, getDownloadURL, uploadBytesResumable, uploadBytes } from "firebase/storage";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { AuthContext } from '../layout/authCtx';

const auth = getAuth();

const preLoadedValues = {
  name: "Stone Name",
  description: "Description",
  brand: "Brand",
  range: "Range",
  finish: "Finish",
  size: "Size",
  dimensions: "Dimensions",
  width: "Width",
  color_pattern: "Colour Pattern",
  price: "Price",
  color: "Color",
  colorValue: "Color Value",
};

const defaultValues = {
  name: "Smartstone - Almendra",
  description: "With its creamy patterns and flecks, Almendra from the Toledo Collection is a classic and versatile surface.",
  brand: "Smartstone",
  range: "New",
  finish: "Polished",
  size: "Jumbo",
  dimensions: "3200 x 1600",
  width: "20mm",
  color_pattern: "No",
  price: "950",
  color: "Black",
  colorValue: "#000"
};

const MyForm = () => {
  const { handleSubmit, control, formState: { errors, isSubmitting, isSubmitSuccessful }, 
    setValue, reset } = useForm({
    defaultValues: defaultValues,
  });
  const {userId, authenticated} = useContext(AuthContext)

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const stonesRef = collection(db, 'stones');
  const storage = getStorage();

  const onSubmit = async (data) => {
    if (authenticated) {
      try {
        console.log("Data: ", data);
        // const files = data.image
        const { image: files, ...stone_details } = data;
  
        // Add document to Firestore - Create a stone 
        const docRef = await addDoc(stonesRef, {
          ...stone_details,
          created: Timestamp.now(),
          uploadedBy: userId
        });
        console.log("Doc created with id: ", docRef.id);
        
        // setSnackbarMessage('Adding Stone Details.');
        // setSnackbarOpen(true);
  
        // Upload each image to Firebase Storage - Add stone image to storage
        if (files && files.length > 0) {
          await Promise.all(files.map(async (file) => {
            const storageRef = ref(storage, `Stone Images/${docRef.id}/${file.name}`);
            await uploadBytes(storageRef, file);
          }));
          console.log('All images uploaded successfully!');
        }
  
        // // Add Brand
        // const BrandRef = doc(db, 'Stone Parameters', 'Brand');
        // await setDoc(BrandRef, { [data.brand]: data.brand }, { merge: true });
        // console.log('Brand updated successfully');
  
        // // Range / Collection
        // const RangeRef = doc(db, 'Stone Parameters', 'Range');
        // await setDoc(RangeRef, { [data.range]: data.range }, { merge: true });
        // console.log('Range updated successfully');
  
        // // Finish
        // const FinishRef = doc(db, 'Stone Parameters', 'Finish');
        // await setDoc(FinishRef, { [data.finish]: data.finish }, { merge: true });
        // console.log('Finish updated successfully');
  
        // // Size
        // const SizeRef = doc(db, 'Stone Parameters', 'Size');
        // await setDoc(SizeRef, { [data.size]: data.size }, { merge: true });
        // console.log('Size updated successfully');
  
        // // Width
        // const WidthRef = doc(db, 'Stone Parameters', 'Width');
        // await setDoc(WidthRef, { [data.width]: data.width }, { merge: true });
        // console.log('Width updated successfully');
  
        // // Color Pattern
        // const ColorPatternRef = doc(db, 'Stone Parameters', 'Color Pattern');
        // await setDoc(ColorPatternRef, { [data.color_pattern]: data.color_pattern }, { merge: true });
        // console.log('Color Pattern updated successfully');
  
        setSnackbarMessage('Form submitted successfully!');
        setSnackbarOpen(true);
      } catch (err) {
        console.log(err);
        setSnackbarMessage('Error submitting the form. Please try again.');
        setSnackbarOpen(true);
      } 
    }
   };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    reset()
  }, [isSubmitSuccessful])  

  return (
    <Box sx={{
      display: "flex", justifyContent: "center", alignItems: "center",
      // backgroundColor: "purple"
    }}>
        <Paper sx={{
          m: 2, px: 1, py: 2, 
          display: "flex", justifyContent: "center", alignItems: "center",
        }}>
            <form onSubmit={handleSubmit(onSubmit)}>
            {Object.keys(preLoadedValues).map((key) => (
            <div key={key}>
            <Controller
              name={key}
              control={control}
              defaultValue=""
              rules={{ required: `${preLoadedValues[key]} is required.` }}
              render={({ field }) => (
                <TextField //fullWidth 
                  sx={{my: 1, width: "400px"}}
                  {...field}
                  label={preLoadedValues[key]}
                  variant="outlined"
                  size="small"
                  error={!!errors[key]}
                  helperText={errors[key]?.message}
                />
              )}
            />
            </div>
            ))}
            <div style={{ margin: '10px 0' }}>
            <Controller
              name="image"
              control={control}
              defaultValue=""
              rules={{ required: "Image is required." }}
              render={({ field }) => (
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const files = Array.from(e.target.files); // Convert FileList to an array
                    setValue('image', files); // Set the array of files using setValue
                  }}
                  multiple  // Allow multiple file selection
                />
              )}
            />
            {errors.image && <p style={{ color: 'red' }}>{errors.image.message}</p>}
            </div>
            <Button type="submit" variant="contained" color="primary" style={{ marginTop: '10px' }} disabled={isSubmitting}>
            Submit
            </Button>
            </form>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
            >
              <Alert
                elevation={6}
                variant="filled"
                onClose={handleCloseSnackbar}
                severity="success"
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>

        </Paper>

    </Box>

  );
};

export default MyForm;
