import "./styles/App.css";
import { Outlet, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ListProduct from "./pages/Product_List";
import DrwingPage from "./pages/Draw";
import UserLayout from "./components/layout/userLayout";
import Footer from "./components/layout/footer";
import ProductPage from "./components/product_page/product";
import Quote from "./pages/Quote";
import AddStone from "./pages/admin/components/add_stone";
import ErrorPage from "./pages/ErrorPage";
import ContactForm from "./pages/Contact";
import QuoteUserForm from "../src/components/quote/quote_form";
import ViewQuote from "./components/quote/quote_view";
import ProviderList from "./components/providers/provider_list";
// Admin Components
import AdminLayout from "./pages/admin/layout/adminLayout";
import AdminDashboard from "./pages/admin/pages/dashboard";
import AdminCustomers from "./pages/admin/pages/customers";
import AdminQuotes from "./pages/admin/pages/quotes";
import AdminAccount from "./pages/admin/pages/account";
import AdminStones from "./pages/admin/pages/stones";
import ManagementAsAdmins from "./pages/admin/pages/manage";
// Auth Components
import Signup from "./pages/admin/auth/signup";
import Login from "./pages/admin/auth/login";
import ResetPassword from "./pages/admin/auth/reset-password";

import { Box } from "@mui/material";
import ProviderHome from "./components/provider_pages/home";
import { Filters } from "./pages/admin/pages/filters";
import { useEffect } from "react";

function App() {
  return (
    <div>
      <Box>
        <Routes>
          <Route path="/" element={<UserLayout />}>
            <Route index element={<Home />} />
            <Route path="/p" element={<ProviderList />} />
            <Route path="/p/:providerId" element={<ProviderHome />} />
            <Route path="/p/:providerId/stones" element={<ListProduct />} />
            {/* <Route path="/stones" element={<ListProduct />} /> */}
            <Route path="/stones/:stone_Id" element={<ProductPage />} />
            <Route path="/quote/plan/:stone_Id" element={<DrwingPage />} />
            <Route path="/quote/adjust" element={<Quote />} />
            <Route path="/quote/address" element={<QuoteUserForm />} />
            <Route path="/quote/view/:quoteId" element={<ViewQuote />} />
            <Route path="/contact" element={<ContactForm />} />
            {/* <Route path="/signup" element={<Signup/>}/> */}
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/add-stone" element={<AddStone />} />
            <Route path="/admin/stones" element={<AdminStones />} />
            <Route path="/admin/customers" element={<AdminCustomers />} />
            <Route path="/admin/quotes" element={<AdminQuotes />} />
            <Route path="/admin/account" element={<AdminAccount />} />
            <Route path="/admin/management" element={<ManagementAsAdmins />} />
            <Route path="/admin/filters" element={<Filters />} />
          </Route>

          {/* <Route path="*" element={<ErrorPage />} /> */}
        </Routes>
        {/* <Footer /> */}
      </Box>
    </div>
  );
}

const AdminBar = () => {
  return (
    <Box>
      Admin Top Bar
      <Outlet />
    </Box>
  );
};

const AdminSideBar = () => {
  return <Box>Admin Side Bar</Box>;
};

export default App;
