import React, { useContext, useEffect, useState } from "react";
import { Container, Box, Paper, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytes,
  getMetadata,
} from "firebase/storage";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Image,
  PDFDownloadLink,
  BlobProvider,
} from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import LoadingPage from "../../pages/LoadingPage";
import { ProviderContext } from "../layout/providerCtx";
import { sendEmail } from "../../utils";

const ViewQuote = () => {
  const { quoteId } = useParams();
  const { setBrandId } = useContext(ProviderContext);
  const [quoteData, setQuoteData] = useState([]);
  const [stoneInfo, setStoneInfo] = useState([]);
  const [customerInfo, setCustomerInfo] = useState([]);
  const [priceSum, setSumPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [brand_name, setBrand_name] = useState("Stone Craft Design");
  const [brandData, setBrandData] = useState([]);
  const [brand_img, setBrand_img] = useState("");
  const [uploadedBy, setUploadedBy] = useState("");
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [uploadInitiated, setUploadInitiated] = useState(false);
  const [installationFee, setInstallationFee] = useState(0);

  console.log("Quote Data: ");
  console.log(quoteData);

  useEffect(() => {
    const fetchQuote = async () => {
      const docRef = doc(db, "Quotes", quoteId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        console.log(quoteId);

        setQuoteData(docSnap.data());
        // alert("Quote Found")
        console.log("Quote Found");
        console.log(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    };
    fetchQuote();
  }, []);

  useEffect(() => {
    if (quoteData?.install === true) {
      if (
        brandData?.Others?.slabs_required &&
        quoteData.stones.length <= Number(brandData?.Others?.slabs_required)
      ) {
        setInstallationFee(Number(brandData?.Others?.installation));
        setTotalPrice(priceSum + Number(brandData?.Others?.installation));
      } else if (brandData?.Others?.slabs_required) {
        setInstallationFee(
          Number(brandData?.Others?.above_than_slab_installation)
        );
        setTotalPrice(
          priceSum + Number(brandData?.Others?.above_than_slab_installation)
        );
      } else {
        setInstallationFee(Number(brandData?.Others?.installation));
        setTotalPrice(priceSum + Number(brandData?.Others?.installation));
      }
    } else {
      setTotalPrice(priceSum);
    }
  }, [quoteData, priceSum, brandData]);

  useEffect(() => {
    let SumofCalPrice =
      quoteData.length !== 0 &&
      quoteData?.stones.reduce(
        (sum, currentObject) => sum + currentObject.calculated_slab_price,
        0
      );
    setSumPrice(SumofCalPrice);
  }, [quoteData]);

  const fetchCustomer = async () => {
    const docRef = doc(db, "Customers", quoteData.customerId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Customer Document data:", docSnap.data());
      setCustomerInfo(docSnap.data());
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const fetchStoneInfo = async () => {
    const docRef = doc(db, "stones", quoteData.stoneId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // console.log("Stone Infor Document data:", docSnap.data());
      setStoneInfo(docSnap.data());
      setUploadedBy(docSnap.data().uploadedBy);
      setBrandId(docSnap.data().uploadedBy);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const PDFComponent = () => {
    return (
      <Document
        title={`Quote ${customerInfo?.firstName} ${customerInfo?.lastName} ${quoteData?.quoteId}`}
      >
        <Page size="A4" style={{ padding: 35 }}>
          {/* Top Header */}
          <View
            style={{
              marginBottom: 20,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>Quote</Text>
          </View>
          <View
            style={{
              marginBottom: 20, // borderBottom: "1px solid black",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ flexGrow: 10 }}>
              <Text>{brand_name}</Text>
              <Text style={styles.text}>Number: {quoteData?.quoteId}</Text>
              <Text style={styles.text}>
                Date:{" "}
                {new Date(quoteData?.createdAt?.toDate()).toLocaleDateString()}
              </Text>
            </View>
            <View style={{ flexGrow: 1, alignItems: "flex-end" }}>
              <Image
                src={brand_img}
                style={{
                  width: "50px",
                  height: "50px",
                  border: "0.5px solid black",
                }}
              />
            </View>
          </View>
          {/* Customer Info and Stone Selected */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={{ flexGrow: 1, width: "100%", maxHeight: "100px" }}>
              <Text
                style={[
                  styles.textheading,
                  { borderBottom: "1px solid black" },
                ]}
              >
                Address
              </Text>
              {customerInfo && customerInfo.length !== 0 ? (
                <View my={1}>
                  <Text style={styles.text}>
                    {customerInfo?.firstName} {customerInfo?.lastName}
                  </Text>
                  <Text style={styles.text}>
                    {customerInfo?.billingAddress}
                  </Text>
                  <Text style={styles.text}>
                    {customerInfo?.suburb}, {customerInfo?.stateTerritory},{" "}
                    {customerInfo?.country}, {customerInfo?.postcode}
                  </Text>
                  <Text style={styles.text}>{customerInfo?.email}</Text>
                  <Text style={styles.text}>{customerInfo?.mobileNumber}</Text>
                </View>
              ) : (
                <Text>Loading</Text>
              )}
            </View>
            <View style={{ flexGrow: 2, width: "100%", maxHeight: "100px" }}>
              <View
                style={{
                  // backgroundColor: "rgba(10, 10, 100, 1)",
                  marginLeft: "50px",
                  paddingRight: "5px",
                }}
              >
                <Text
                  style={[
                    styles.textheading,
                    { borderBottom: "1px solid black" },
                  ]}
                >
                  Stone Details
                </Text>
              </View>
              {stoneInfo && stoneInfo.length !== 0 ? (
                <View style={{ marginLeft: "50px" }} my={1}>
                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={[styles.text, { flexBasis: "40%" }]}>
                      Name
                    </Text>
                    <Text style={[styles.text, { flexBasis: "60%" }]}>
                      {stoneInfo?.name}
                    </Text>
                  </View>

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={[styles.text, { flexBasis: "40%" }]}>
                      Brand & Range
                    </Text>
                    <Text style={[styles.text, { flexBasis: "60%" }]}>
                      {stoneInfo?.brand}, {stoneInfo?.range}
                    </Text>
                  </View>

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={[styles.text, { flexBasis: "40%" }]}>
                      Dimensions
                    </Text>
                    <Text style={[styles.text, { flexBasis: "60%" }]}>
                      {stoneInfo?.size}, {stoneInfo?.dimensions}
                    </Text>
                  </View>

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={[styles.text, { flexBasis: "40%" }]}>
                      Color Pattern
                    </Text>
                    <Text style={[styles.text, { flexBasis: "60%" }]}>
                      {stoneInfo?.color_pattern}
                    </Text>
                  </View>

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={[styles.text, { flexBasis: "40%" }]}>
                      Color
                    </Text>
                    <Text style={[styles.text, { flexBasis: "60%" }]}>
                      {stoneInfo?.color}
                    </Text>
                  </View>

                  <View style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={[styles.text, { flexBasis: "40%" }]}>
                      Price
                    </Text>
                    <Text style={[styles.text, { flexBasis: "60%" }]}>
                      ${stoneInfo?.price}
                    </Text>
                  </View>
                </View>
              ) : (
                <Text>Loading</Text>
              )}
            </View>
          </View>
          {/* Stone Sketch */}
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Text style={[styles.textheading, { alignSelf: "flex-start" }]}>
              Sketch
            </Text>
            <View
              style={{
                border: "1px solid black",
                marginTop: "5px",
                padding: "10px",
              }}
            >
              <Image
                style={{ width: "500px", height: "500px" }}
                source={quoteData?.stonePlan}
              />
            </View>
          </View>
        </Page>
        <Page size="A4" style={{ padding: 35 }}>
          <View>
            <Text style={styles.textheading}>Details</Text>
            <View>
              <View
                component={Paper}
                style={{
                  marginTop: "10px",
                  padding: "10px",
                  border: "1px solid #ddd",
                }}
              >
                <View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      borderBottom: "1px solid #ddd",
                      paddingBottom: "5px",
                    }}
                  >
                    <View style={{ flexBasis: "25%", marginRight: "10px" }}>
                      <Text style={{ fontSize: "10px", fontWeight: "bold" }}>
                        Name (Dimensions)
                      </Text>
                    </View>
                    <View style={{ flexBasis: "35%", marginRight: "10px" }}>
                      <Text style={{ fontSize: "10px", fontWeight: "bold" }}>
                        Details
                      </Text>
                    </View>
                    <View style={{ flexBasis: "20%", marginRight: "10px" }}>
                      <Text style={{ fontSize: "10px", fontWeight: "bold" }}>
                        Slab Proportion
                      </Text>
                    </View>
                    <View style={{ flexBasis: "20%" }}>
                      <Text style={{ fontSize: "10px", fontWeight: "bold" }}>
                        Stone Price
                      </Text>
                    </View>
                  </View>
                  {quoteData?.stones &&
                    quoteData?.stones?.map((stone) => (
                      <View
                        key={stone.name}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          borderBottom: "1px solid #ddd",
                          padding: "5px 0",
                        }}
                      >
                        <View style={{ flexBasis: "25%", marginRight: "10px" }}>
                          <Text style={{ fontSize: "10px" }}>
                            {stone.name} ({stone.width} x {stone.height})
                          </Text>
                        </View>
                        <View style={{ flexBasis: "35%", marginRight: "10px" }}>
                          <Text style={{ fontSize: "10px" }}>
                            Edge Type: {stone.edgeType}, Edge Profile:{" "}
                            {stone.edgeProfile}, Sink Cut: {stone.sinkCut},
                            Cooktop Cut: {stone.cookTopCut}
                            {stone.waterfalls && ", Waterfall"}{" "}
                            {stone.splashbacks && ", Splash Back"}
                          </Text>
                        </View>
                        <View style={{ flexBasis: "20%", marginRight: "10px" }}>
                          <Text style={{ fontSize: "10px" }}>
                            {stone.quantity}
                          </Text>
                        </View>
                        <View style={{ flexBasis: "20%" }}>
                          <Text style={{ fontSize: "10px" }}>
                            ${stone.calculated_slab_price}
                          </Text>
                        </View>
                      </View>
                    ))}
                  <View
                    style={{
                      alignSelf: "flex-end",
                      padding: 2,
                      marginTop: 1,
                      width: "75%",
                    }}
                  >
                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Text style={[styles.text, { flexBasis: "75%" }]}>
                        Sum price of all stones
                      </Text>
                      <Text style={[styles.text, { flexBasis: "25%" }]}>
                        ${priceSum}
                      </Text>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row" }}>
                      {/* <Text style={[styles.text, {flexBasis: "75%", alignItems: "flex-end"}]}>
                    Sum Price of all stones
                    </Text>
                    <Text style={[styles.text, {flexBasis: "25%", alignItems: "flex-end"}]}>
                    {priceSum}
                    </Text> */}
                      <Text style={styles.text}>
                        The installation fee is $
                        {installationFee || "Not Mentioned"} if applicable.
                      </Text>
                    </View>

                    <View style={{ display: "flex", flexDirection: "row" }}>
                      <Text style={[styles.text, { flexBasis: "75%" }]}>
                        Total price of all stones plus installation charges
                      </Text>
                      <Text style={[styles.text, { flexBasis: "25%" }]}>
                        ${totalPrice}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View>
            <Text style={{ marginTop: 8, fontWeight: "bold", fontSize: 10 }}>
              Please be advised, this quotation is just an estimate. Please
              contact {brandData.phone} for price confirmation.
            </Text>
          </View>
        </Page>
      </Document>
    );
  };
  useEffect(() => {
    if (quoteData && quoteData.length !== 0) {
      fetchCustomer();
    }
  }, [quoteData]);

  useEffect(() => {
    if (quoteData && quoteData.length !== 0) {
      fetchStoneInfo();
    }
  }, [quoteData]);

  useEffect(() => {
    const FetchCompany = async () => {
      const docRef = doc(db, "Brands", uploadedBy);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setBrand_name(docSnap.data().name);
        setBrandData(docSnap.data());
        // alert(docSnap.data().name)
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document fr company name!");
      }

      try {
        const storage = getStorage();
        const storageRef = ref(storage, `Brands/${uploadedBy}/logo.png`);
        const url = await getDownloadURL(storageRef);

        // alert(url)
        setBrand_img(url);
      } catch (error) {
        // Handle any errors
        console.error("Error downloading image:", error);
      }
    };
    if (uploadedBy !== "" && uploadedBy !== "undefined") {
      FetchCompany();
    }
  }, [uploadedBy, quoteData]);

  // test
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const storage = getStorage();
        const storageRef = ref(storage, `Brands/${uploadedBy}/logo.png`);
        const url = await getDownloadURL(storageRef);

        setBrand_img(url);
        setImagesLoaded(true);
      } catch (error) {
        // Handle any errors
        console.error("Error downloading image:", error);
      }
    };

    if (uploadedBy !== "" && uploadedBy !== "undefined") {
      fetchImages();
    }
  }, [uploadedBy, quoteData]);

  const uploadPdfToFirebase = async (file) => {
    // Upload file to Firebase Storage
    const storage = getStorage();
    // Create a reference to the file in Firebase Storage
    const fileRef = ref(storage, `PDFs/${quoteId}`);
    try {
      // Attempt to get the download URL of the file (checking if it exists)
      await getDownloadURL(fileRef);

      // If no error is thrown, it means the file already exists
      console.log("File already exists.");
      return; // Return early if the file already exists
    } catch (error) {
      // If an error is thrown, the file doesn't exist
      if (error.code === "storage/object-not-found") {
        // Upload the file to Firebase Storage
        await uploadBytes(fileRef, file);

        // Get the download URL of the uploaded file
        const url = await getDownloadURL(fileRef);
        sendEmail(url, brandData.email);
        console.log("File uploaded successfully. URL:", url);
        return url; // Return the download URL if needed
      } else {
        // Handle other errors
        console.error("Error uploading file:", error);
        throw error;
      }
    }
  };
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {brand_img == "" ||
      quoteData.length == 0 ||
      customerInfo.length == 0 ||
      stoneInfo.length == 0 ||
      brandData.length == 0 ? (
        <LoadingPage />
      ) : (
        <Box sx={{ width: "100vw", minHeight: "200vh" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {/* <PDFViewer
              showToolbar={false}
              style={{
                width: "100vw",
                height: "200vh",
                backgroundColor: "gray",
              }}
            >
              <PDFComponent />
            </PDFViewer> */}
            <BlobProvider document={<PDFComponent />}>
              {({ blob, url, loading, error }) => {
                if (blob && !uploadInitiated) {
                  // Upload the blob to Firebase only if it exists and the upload has not been initiated yet
                  setUploadInitiated(true); // Set upload initiation state to prevent multiple uploads
                  uploadPdfToFirebase(blob); // Call the upload function
                }

                return (
                  <>
                    <PDFViewer
                      showToolbar={false}
                      style={{
                        width: "100vw",
                        height: "200vh",
                        backgroundColor: "gray",
                      }}
                    >
                      <PDFComponent />
                    </PDFViewer>
                  </>
                );
              }}
            </BlobProvider>

            <Button
              component={PDFDownloadLink}
              variant="contained"
              document={<PDFComponent />}
              fileName={`Quote ${customerInfo?.firstName} ${customerInfo?.lastName} ${quoteData?.quoteId}`}
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading document..." : "Download now!"
              }
              Download
            </Button>
            {/* <img src={brand_img} height={"100px"} width={"100px"}/>
            <PDFDownloadLink document={<PDFComponent />} fileName={`Quote ${customerInfo?.firstName} ${customerInfo?.lastName} ${quoteData?.quoteId}`}>
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Download now!'
              }
            </PDFDownloadLink> */}
          </Box>
        </Box>
      )}
    </Container>
  );
};

const styles = {
  textheading: {
    fontWeight: "bold",
    fontSize: 10,
  },
  text: {
    fontWeight: "light",
    fontSize: 10,
  },
};

export default ViewQuote;
