import React from "react";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const LoadingPage = () => {
  const containerStyle = {
    textAlign: "center",
    marginTop: 150,
    marginBottom: 150,
  };

  const loadingTextStyle = {
    fontSize: "2em",
    fontWeight: "bold",
    color: "black", // You can adjust the color as needed
  };

  return (
    <div style={containerStyle}>
      <Box>
        <CircularProgress color="primary" size={80} />
      </Box>
      <Typography variant="h5" style={loadingTextStyle}>
        Loading
      </Typography>
    </div>
  );
};

export default LoadingPage;
