import { Paper, Typography } from "@mui/material"

const NoProductsFound = () => {
    const paperStyle = {
        padding: '16px',
        marginBottom: "100px",
        marginTop: "100px",
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.6)',
    };

    return (
        <Paper style={paperStyle}>
            <Typography variant="h5" component="div">
                No products found
            </Typography>
            <Typography>
                Sorry, there are no products available at the moment.
            </Typography>
        </Paper>
    );
};

export default NoProductsFound