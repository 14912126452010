import React, {useEffect, useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Paper, Box, CardActionArea, Skeleton } from '@mui/material';
import { Link } from "react-router-dom";
import '../../styles/App.css'
import { useNavigate } from "react-router-dom";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";

export default function StoneCard(props) {
  const [headImg, setHeadImg] = useState(null)
  const navigate = useNavigate();

  useEffect(()=>{
    const fetchURL = async () => {
      const storage = getStorage();
      const folderRef = ref(storage, `Stone Images/${props.id}`)

      try {
        const items = await listAll(folderRef);
    
        if (items.items.length > 0) {
          const firstItemRef = items.items[0];
          const downloadURL = await getDownloadURL(firstItemRef);
          setHeadImg(downloadURL)

          // console.log("Download URL of the first item:", downloadURL);
          return downloadURL;
        } else {
          console.log("Folder is empty.");
          return null;
        }
      } catch (error) {
        console.error("Error retrieving items in the folder:", error);
        return null;
      }
    

    }
    fetchURL()

  }, [])

  return (
    <Paper
      onClick={()=>navigate(`/stones/${props.id}`)} 
      square sx={{ 
        cursor: "pointer",
        width: 300, maxWidth: 345,
        transition: '2s',
        backgroundColor: '#333', color: 'white',
        // backgroundColor: '#888', color: 'black',
        margin: '0.5rem',
        border: '1px solid #333',
        '&:hover': {
          backgroundColor: '#888', color: 'black',
          // backgroundColor: '#333', color: 'white',
          opacity: 0.9, 
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          transform: 'scale(1.02)',
          transition: '0.5s',
        },

      }}>

      {/* https://txwes.libguides.com/c.php?g=978475&p=7075536 */}
      {/* Above URL for various shades of black and gray */}
      {/* <CardActionArea> */}
      {
        headImg && headImg !== null
        ? 
        <CardMedia
          sx={{ height: 160 }}
          image={headImg}
          title={props.name}
          component='img'
        />
        : <Skeleton sx={{ height: 160, bgcolor: '#d3d3d3' }} animation="wave" variant="rectangular" />
      }

        <Box sx={{px: 2, pt: 2, pb: 1}}>

          <Typography 
            variant="body1" sx={{ cursor: 'pointer', fontWeight: '700' }}
          >
              {props.name}
          </Typography>


          <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
            <Typography variant='subtitle2' sx={{mr: 0.5}}>
            {props.width}
            </Typography>
            <Typography variant='subtitle2' sx={{mr: 0.5}}>
            {props.size}
            </Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mt: 1}}> 
            <Box>
              {/* <Box sx={{display: 'flex', }}>
                {
                  props.Tones.map((t)=>{
                    let tv = t['tone_value']
                    return (
                      <Box key={JSON.stringify(t)} sx={{width: 15, height: 15, backgroundColor: tv, mr: 0.5}}></Box>
      
                    )
                  })
                }
              </Box> */}
              
              <Box sx={{display: 'flex', }}>
                <Typography variant="overline" >
                {props.collection} 
                {/* =&gt; &nbsp; {props.id} */}
                </Typography>
              </Box>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Typography variant='h5'>
                ${props.price}
              </Typography>
            </Box>
          </Box>
        </Box>
      {/* </CardActionArea> */}
    </Paper>
  );
}
