import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  const containerStyle = {
    textAlign: "center",
    marginTop: 150,
    marginBottom: 150,
  };

  const errorTextStyle = {
    fontSize: "8em",
    fontWeight: "bold",
    color: "red", // You can adjust the color as needed
  };

  const messageStyle = {
    marginTop: "20px",
  };

  const buttonStyle = {
    marginTop: "30px",
    backgroundColor: "black",
  };

  return (
    <div style={containerStyle}>
      <Typography variant="h1" style={errorTextStyle}>
        404
      </Typography>
      <Typography variant="h5" style={messageStyle}>
        Oops! Page not found.
      </Typography>
      <Button
        component={Link}
        to="/"
        variant="contained"
        color="primary"
        style={buttonStyle}
      >
        Go to Home
      </Button>
    </div>
  );
};

export default ErrorPage;
