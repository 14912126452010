import React, {useEffect, useState, useContext} from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { db } from '../../../firebase/firebase-config';
import {collection, query, where, getAggregateFromServer, sum, getDocs} from 'firebase/firestore'
import { AuthContext } from '../layout/authCtx';
import LoadingPage from '../../LoadingPage';
import { Navigate, useNavigate } from 'react-router-dom';
import Calculator from '../components/calculator';

const AdminDashboard = () => {
  const navigate = useNavigate()
  const { authenticated, setAuthenticated, userId, setUserId, superadmin } = useContext(AuthContext)
  const {loading, setLoading} = useContext(AuthContext);
  const [quotesSum, setQuotesSum] = useState(0)
  const [stonesSum, setStonesSum] = useState(0)
  const [customerSum, setCustmerSum] = useState(0)
  const [brandSum, setBrandSum] = useState(0)

  useEffect(() => {

    setLoading(true);
  
    const fetchSum = async () => {
      // Get the total count of documents where uploadedBy is "xyz" in the 'Quotes' collection
      const quoteColl = collection(db, 'Quotes');
      // const quoteQuery = query(quoteColl, where('uploadedBy', '==', userId));
      const quoteQuery = superadmin ? query(quoteColl) : query(quoteColl, where('provider', '==', userId));
      const quoteSnapshot = await getDocs(quoteQuery);
      console.log('Total Count of Documents in Quotes:', quoteSnapshot.size);
      setQuotesSum(quoteSnapshot.size);
  
      // Get the total count of documents where uploadedBy is "xyz" in the 'Customers' collection
      const customerColl = collection(db, 'Customers');
      const customerQuery = superadmin ? query(customerColl) : query(customerColl, where('provider', '==', userId));
      const customerSnapshot = await getDocs(customerQuery);
      console.log('Total Count of Documents in Customers:', customerSnapshot.size);
      setCustmerSum(customerSnapshot.size);
  
      // Get the total count of documents where uploadedBy is "xyz" in the 'stones' collection
      const stoneColl = collection(db, 'stones');
      const stoneQuery = superadmin ? query(stoneColl) : query(stoneColl, where('uploadedBy', '==', userId));
      const stoneSnapshot = await getDocs(stoneQuery);
      console.log('Total Count of Documents in Stones:', stoneSnapshot.size);
      setStonesSum(stoneSnapshot.size);
  
      // Get the total count of documents where uploadedBy is "xyz" in the 'Brands' collection
      const brandColl = collection(db, 'Brands');
      const brandQuery = query(brandColl);
      const brandSnapshot = await getDocs(brandQuery);
      console.log('Total Count of Documents in Brands:', brandSnapshot.size);
      setBrandSum(brandSnapshot.size);
  
      // Set Loading to False
      setLoading(false);
    };
  
    if (userId!==null || userId!=="undefined") {
      fetchSum();
    }
  }, []);
    
  return (
    <Box p={3}>
      {
        loading?
        <LoadingPage/>:
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Paper onClick={() => navigate("/admin/stones")} elevation={3} sx={{ 
              height: '100%', width: '100%', minHeight: "150px", bgcolor: "orange", cursor: "pointer",
              display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" 
            }}>
              <Typography variant="h6" gutterBottom>
                Stones
              </Typography>
              <Typography variant="h4">
              {stonesSum}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper onClick={() => navigate("/admin/customers")} elevation={3} sx={{ 
              height: '100%', width: '100%', minHeight: "150px", bgcolor: "orange", cursor: "pointer",
              display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" 
            }}>
              <Typography variant="h6" gutterBottom>
                Customers
              </Typography>
              <Typography variant="h4">
                {customerSum}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={3}>
            <Paper onClick={() => navigate("/admin/quotes")} elevation={3} sx={{ 
              height: '100%', width: '100%', minHeight: "150px", bgcolor: "orange", cursor: "pointer",
              display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" 
            }}>
              <Typography variant="h6" gutterBottom>
                Quotes
              </Typography>
              <Typography variant="h4">
                {quotesSum}
              </Typography>
            </Paper>
          </Grid>

          {superadmin?
            <Grid item xs={12} md={3}>
            <Paper onClick={() => navigate("/admin/management")} elevation={3} sx={{ 
              height: '100%', width: '100%', minHeight: "150px", bgcolor: "orange", cursor: "pointer",
              display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" 
            }}>
              <Typography variant="h6" gutterBottom>
                Brands / Admins
              </Typography>
              <Typography variant="h4">
                {brandSum}
              </Typography>
            </Paper>
          </Grid>:null}



        </Grid>
      }
      <Box>
        <Calculator/>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
