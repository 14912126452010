import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  Drawer,
  Paper,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { AuthContext } from "./authCtx";

const drawerWidth = 200;

let SideBarLinks = [
  { name: "Dashboard", path: "dashboard" },
  { name: "Stones", path: "stones" },
  { name: "Customers", path: "customers" },
  { name: "Quotes", path: "quotes" },
  { name: "Account", path: "account" },
];

const AdminSideBar = () => {
  const { superadmin } = useContext(AuthContext);
  const [mobileOpen, setMobileOpen] = useState(false);

  // if (superadmin) {
  SideBarLinks = [
    { name: "Dashboard", path: "dashboard" },
    { name: "Stones", path: "stones" },
    { name: "Customers", path: "customers" },
    { name: "Quotes", path: "quotes" },
    { name: "Account", path: "account" },
    { name: "Admins Management", path: "management" },
    { name: "Filters", path: "filters" },
  ];
  // } else {
  //   SideBarLinks = [
  //     { name: "Dashboard", path: "dashboard" },
  //     { name: "Stones", path: "stones" },
  //     { name: "Customers", path: "customers" },
  //     { name: "Quotes", path: "quotes" },
  //     { name: "Account", path: "account" },
  //   ];
  // }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      {/* Button to open the drawer on small screens */}
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          backgroundColor: "white",
          display: { xs: "block", sm: "none" },
          transform: "rotate(90deg)",
          position: "fixed",
          top: "20vh", // Adjust the percentage as needed
          left: "1rem", // Adjust the distance from the left as needed
          zIndex: 1, // Ensure it appears above other elements
        }}
      >
        <MenuIcon />
      </IconButton>

      {/* Drawer for small screens */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { width: drawerWidth },
        }}
      >
        <Paper sx={{ width: drawerWidth, p: 1, minHeight: "70vh" }}>
          <Box sx={{ overflow: "auto" }}>
            <List>
              {SideBarLinks.map((item) => (
                <ListItem key={item.name} disablePadding>
                  <ListItemButton
                    component={Link}
                    to={item.path}
                    onClick={handleDrawerToggle}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {item.name}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Paper>
      </Drawer>

      {/* Sidebar for large screens */}
      <Box
        component="nav"
        sx={{
          width: drawerWidth,
          flexShrink: { xs: 0, sm: 0 },
          display: { xs: "none", sm: "block" },
        }}
      >
        <Paper sx={{ width: drawerWidth, p: 1, minHeight: "70vh" }}>
          <Box sx={{ overflow: "auto" }}>
            <List>
              {SideBarLinks.map((item) => (
                <ListItem key={item.name} disablePadding>
                  <ListItemButton component={Link} to={item.path}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {item.name}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default AdminSideBar;
